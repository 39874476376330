import { useState, useEffect} from "react";
import { useSelector } from 'react-redux';
import { Await, useMatch } from 'react-router-dom';
import { GetRetaillist } from "./dbfirebase";
import { ToastContainer, toast } from 'react-toastify';
import { modificarOrdenTaller } from "./dbfirebase";
import { doc, collection, updateDoc, getDocs, where, query, setDoc, QuerySnapshot, Query } from "firebase/firestore"
import { db } from "../components/firebase";
import { useNavigate } from 'react-router-dom';



export const EditTaller= () => {

    
    const sku = useSelector(state => state.productData.products)
    const [originalTaller, setOriginalTaller] = useState({});
    const [historialTaller, setHistorialTaller] = useState([]);
    const [sended, setSended] = useState()
    const navigate = useNavigate()

    const asteriscoStyle = {
        color: 'red',
        fontSize: '1.2em', // Tamaño del asterisco
        fontWeight: 'bold', // Texto en negrita
        verticalAlign: 'super', // Alineación vertical para superíndice
      };
  

    const match = useMatch('/ordenesTaller/modificar/:Id')
    const id = match.params.Id


    const tallerdata = useSelector(state => state.tallerInfo)
    .filter(item => item.Id === id)
    // .map(item => mapDataToTaller(item));

   

    const [taller, setTaller] = useState({
        
    Fecha_repuestos: '',
    Oper_Alta_repuesto: '',
    Localidad: '',
    Desperfecto: '',
    Garantia: true,
    Domicilio_cli: '',
    Tipo: '',
    fantacia: '',
    Telefono_cli: '',
    Comentarios: '',
    Descripcion: '',
    Email_cli: '',
    AccessUUID: '',
    Mdo: '',
    Provincia: '',
    CuitDni_cli: '',
    RazonSocialCli: '',
    App: '',
    Fecha_encentral: '',
    NumRemito: '',
    Titular: '',
    Estado: '',
    Repuestos: [],
    Taller: '',
    iddoc: '',
    Id: '',
    Fecha_reparando: '',
    UUID: '',
    FechaCompra: '',
    Fecha_finalizado: '',
    Telefono: '',
    Articulo: '',
    Categoria: '',
    Cuit: '',
    Num_Serie: '',
    Deposito: '',
    Fecha: '',
    Email: '',
    Direccion: '',
    Cliente: ' ',
    NumFactura: '',
    Permiso: '',
    TimeStamp: new Date(), 
    state: '',
    })
 

const handleLoadData = () => {
        if (tallerdata.length > 0) {
            const data = tallerdata[0];
            setOriginalTaller(data);
            setTaller(prevCliente => ({
                ...prevCliente,
                Fecha_repuestos: data.Fecha_repuestos|| '-',
                Oper_Alta_repuesto:data.Oper_Alta_repuesto|| '-',
                Localidad:data.Localidad|| '-',
                Desperfecto:data.Desperfecto|| '-',
                Garantia: data.Garantia|| '-',
                Domicilio_cli: data.Domicilio_cli|| '-',
                Tipo: data.Tipo|| '-',
                fantacia:data.fantacia|| '-',
                Telefono_cli: data.Telefono_cli|| '-',
                Comentarios: data.Comentarios|| '-',
                Descripcion: data.Descripcion|| '-',
                Email_cli: data.Email_cli|| '-',
                AccessUUID: data.AccessUUID|| '-',
                Mdo: '-',
                Provincia: data.Provincia|| '-',
                CuitDni_cli: data.CuitDni_cli|| '-',
                RazonSocialCli: data.RazonSocialCli|| '-',
                App: data.App|| '-',
                Fecha_encentral: data.Fecha_encentral|| '-',
                NumRemito:data.NumRemito|| '-',
                Titular: data.Titular|| '-',
                Estado: data.Estado|| '-',
                Repuestos: data.Repuestos|| '-',
                Taller: data.Talleraller|| '-',
                iddoc: data.iddoc|| '-',
                Id: data.Id|| '-',
                Fecha_reparando:data.Fecha_reparando|| '-',
                UUID: data.UUID|| '-',
                FechaCompra:data.FechaCompra|| '-',
                Fecha_finalizado: data.Fecha_finalizado|| '-',
                Telefono: data.Telefono|| '-',
                Articulo: data.Articulo|| '-',
                Categoria: data.Categoria|| '-',
                Cuit: data.Cuit|| '-',
                Num_Serie: data.Num_Serie|| '-',
                Deposito: data.Deposito|| '-',
                Fecha: data.Fecha|| '-',
                Email: data.Email|| '-',
                Direccion: data.Direccion|| '-',
                Cliente: data.Cliente|| '-',
                NumFactura:data.NumFactura|| '-',
                Permiso: data.Permiso|| '-',
                TimeStamp: new Date(),
                state: data.state|| '-',
            }));
        }   
    }; 


    useEffect(() =>{
        handleLoadData()
    },[ ])

    //    '-', para no mandar campos vacios a Firebase por que rompe 


    const handleForm1Change = (e) => {
        const { name, value } = e.target;
        setTaller((prevState) => ({ ...prevState, [name]: value  }));
    };  

    const handleModificarClick = async () => {
        try {
            
            // esto anda como dios manda
            const updatedHistorialTaller = [
                {
                  modificado: {
                    ...taller,
                    original: [{ ...originalTaller, state: 'archivo ' }],
                  },
                },
                ...historialTaller,
              ];

              if (updatedHistorialTaller[0].modificado.hasOwnProperty('original')) {
                // Anidar el segundo objeto al array 'original'
                updatedHistorialTaller[0].modificado.original.push({ ...originalTaller, state: 'archivo' });
              }
              
              // Si no existe 'original', puedes inicializarlo como un array con el nuevo objeto
              else {
                updatedHistorialTaller[0].modificado.original = [{ ...originalTaller, state: 'archivo' }];
              }

              setHistorialTaller(updatedHistorialTaller);


      
          // Llama a la función modificarOrdenTaller con el historial actualizado
          await modificarOrdenTaller(id, updatedHistorialTaller);
      
          setSended(true);
      
          toast.success(
            'Datos cargados con éxito', {
            position: "top-left",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
          });
      
          // Descomenta si quieres recargar la página después de un retraso
          await new Promise(resolve => setTimeout(resolve, 2100));
          navigate('/ordenesTaller');
                setTimeout(() => {
                    window.location.reload();
                }, 2500);
        } catch (error) {
            console.error(error);
          alert("Ocurrió un error");
        }
      };
                 
   
    useEffect(() => {
    }, [historialTaller]);


    


    return (
        <form className= 'd-flex justify-content-center card' style={{ marginBottom: '20px' }}>
         <div className="row " style={{ borderBottom: '1px solid', width: '100%', borderBottomColor: '#bcbcbc',padding:'50px' }}>
         <ToastContainer />

        <div className='col-lg-6 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}> Fecha<span style={asteriscoStyle}>*</span>:</label>
                <input
                type="text"
                name="Fecha"
                className="form-control"
                placeholder="Sin guiones ni puntos"
                id="Fecha"
                onChange={handleForm1Change}
                value={taller.Fecha}
                style={{ maxWidth: '20rem', border: taller?.Fecha && taller?.Fecha.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                disabled={sended ? true : false}
               
            />

        </div>

          <div className='col-lg-6 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Cuit/dni<span style={asteriscoStyle}>*</span>:</label>
                <input
                type="text"
                name="CuitDni_cli"
                className="form-control"
                placeholder="Sin guiones ni puntos"
                id="CuitDni_cli"
                onChange={handleForm1Change}
                value={taller.CuitDni_cli}
                style={{ maxWidth: '20rem', border: taller?.CuitDni_cli&&taller?.CuitDni_cli.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                disabled={sended ? true : false}
                
            />
            </div>

            
          <div className='col-lg-6 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Titular<span style={asteriscoStyle}>*</span>:</label>
                <input
                type="text"
                name="Titular"
                className="form-control"
                placeholder="Sin guiones ni puntos"
                id="Titular"
                onChange={handleForm1Change}
                value={taller.Titular}
                style={{ maxWidth: '20rem', border:taller?.Titular&&taller?.Titular.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                disabled={true}
                
            />
            </div>
        
            
            <div className='col-lg-6 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}> Cliente <span style={asteriscoStyle}>*</span>:</label>
                <input
                type="text"
                name="Cliente"
                className="form-control"
                placeholder="Sin guiones ni puntos"
                id="Cliente"
                onChange={handleForm1Change}
                value={taller.Cliente}
                style={{ maxWidth: '20rem', border: taller?.Cliente&&taller?.Cliente.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                disabled={sended ? true : false}
               
            />
            </div>

            <div className='col-lg-6 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Categoria<span style={asteriscoStyle}>*</span>:</label>
                <input
                type="text"
                name="Categoria"
                className="form-control"
                placeholder="Sin guiones ni puntos"
                id="Categoria"
                onChange={handleForm1Change}
                value={taller.Categoria}
                style={{ maxWidth: '20rem', border: taller?.Categoria&&taller?.Categoria.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                disabled={sended ? true : false}
                
            />
            </div>

            <div className='col-lg-6 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Fecha Compra<span style={asteriscoStyle}>*</span>:</label>
                <input
                type="text"
                name="FechaCompra"
                className="form-control"
                placeholder="Sin guiones ni puntos"
                id=" FechaCompra"
                onChange={handleForm1Change}
                value={taller.FechaCompra}
                style={{ maxWidth: '20rem', border: taller?.FechaCompra&&taller?.FechaCompra.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                disabled={sended ? true : false}
               
            />
            </div>

            <div className='col-lg-6 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}> Num Remito<span style={asteriscoStyle}>*</span>:</label>
                <input
                type="text"
                name="NumRemito"
                className="form-control"
                placeholder="Sin guiones ni puntos"
                id="NumRemito"
                onChange={handleForm1Change}
                value={taller.NumRemito}
                style={{ maxWidth: '20rem', border: taller?.NumRemito&& taller?.NumRemito.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                disabled={sended ? true : false}
                
            />
            </div>

            <div className='col-lg-6 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}> Taller <span style={asteriscoStyle}>*</span>:</label>
                <input
                type="text"
                name="Taller"
                className="form-control"
                placeholder="Sin guiones ni puntos"
                id="Taller"
                onChange={handleForm1Change}
                value={taller.Taller}
                style={{ maxWidth: '20rem', border: taller?.Taller && taller?.Taller.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                disabled={sended ? true : false}
                
            />
            </div>

            
            <div className='col-lg-6 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}> Articulo <span style={asteriscoStyle}>*</span>:</label>
                <input 
                                    type = "text"
                                    name = "Articulo"
                                    className = "form-control"
                                    placeholder = "Articulo"
                                    id = "Articulo"
                                    autoComplete="off"
                                    list='articulo'
                                    onChange={(e) => { e.target.value = e.target.value.toUpperCase(); handleForm1Change(e); }}
                                    style={{ maxWidth: '20rem', border: taller?.Articulo && taller?.Articulo.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                                    //disabled={!taller.Categoria || !taller.Tipo}
                                    value={taller.Articulo}
                                    />
                                    <datalist id="articulo">
                                        {/* {taller.Tipo && categorias.filter((categoria) => (
                                            categoria.Grupo === taller.Categoria.replace(/\s/g, ''))).map((producto) => {
                                                try{
                                                    return Object.keys(producto[`${taller.Tipo.replace(/\s/g, '')}`][0]).map((codigo) =>(
                                                        <option key={codigo} value={codigo}/>
                                                    ))
                                                }catch (error){
                                                    return null
                                                }
                                            }) ||  */}
                                        {sku &&
                                            sku.map(art => (
                                                <option key={art} value={art}>
                                                    {art}
                                                </option>
                                            ))
                                            }
                                    </datalist>
            </div>
            
            <div className='col-lg-6 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}> Observacion <span style={asteriscoStyle}>*</span>:</label>
                <input
                type="text"
                name="Comentarios"
                className="form-control"
                placeholder="Sin guiones ni puntos"
                id="Comentarios"
                onChange={handleForm1Change}
                value={taller.Comentarios}
                style={{ maxWidth: '20rem', border: taller?.Comentarios&&taller?.Comentarios.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                disabled={sended ? true : false}
               
            />
            </div>

            <div className='col-lg-6 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}> Falla <span style={asteriscoStyle}>*</span>:</label>
                <input
                type="text"
                name="Desperfecto"
                className="form-control"
                placeholder="Sin guiones ni puntos"
                id="Desperfecto"
                onChange={handleForm1Change}
                value={taller.Desperfecto}
                style={{ maxWidth: '20rem', border: taller?.Desperfecto&&taller?.Desperfecto.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                disabled={sended ? true : false}
                
            />
            </div>

            <div className='col-lg-6 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>  Numero Serie <span style={asteriscoStyle}>*</span>:</label>
                <input
                type="text"
                name="Num_Serie"
                className="form-control"
                placeholder="Sin guiones ni puntos"
                id="Num_Serie"
                onChange={handleForm1Change}
                value={taller.Num_Serie}
                style={{ maxWidth: '20rem', border: taller?.Num_Serie&&taller?.Num_Serie.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                disabled={sended ? true : false}
                
            />
            </div>



            <div className='col-lg-6 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}> Estado <span style={asteriscoStyle}>*</span>:</label>
                <input
                type="text"
                name="Estado"
                className="form-control"
                placeholder="Sin guiones ni puntos"
                id="Estado"
                onChange={handleForm1Change}
                value={taller.Estado}
                style={{ maxWidth: '20rem', border: taller?.Estado&&taller?.Estado.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                disabled={sended ? true : false}
               
            />
            </div> 


            <div className='col-12 d-flex justify-content-center d-print-none' style={{ marginBottom: '20px' }}>
                 <button type="submit" onClick={() => { handleModificarClick()}} className='col-12 btn btn-success w-50' disabled={sended ? true : false} >Guardar</button>
            </div>
    
        </div>
    </form>
    
    )

};

    
    
    




   