import React, {useState, useEffect} from 'react';
import {useAuth} from "../context/authcontext";
//import {Success} from "./alert";
import cerrarsesion from '../images/cerrar-sesion.png'
import {Link} from 'react-router-dom'
import {GetTaller} from './dbfirebase'
import { useDispatch, useSelector } from 'react-redux';
import {Conect, SLCred} from './dbfirebase'
import { addSapInfo, setSessionID } from '../store/SapInfo/SapInfoSlice'
import {useGeneral} from '../context/generalContext'
import SapLogo from '../images/SAP.png'
import SapLogoOff from '../images/SAPOff.png'
import axios from 'axios';

export function Showafterloged(){

    const [dato, setDato] = useState([])
    const {user, logout} = useAuth();
    const {userData, Sl} = useGeneral()

    const dispatch = useDispatch();
    const sap = useSelector(state => state.SapInfo)

    const datacred = SLCred()
    
    const handlelogout = async () => {
        try{
            await logout();
        } catch (error) {
            console.log(error)
        }
    };

    const data = async() =>{ 
        const talleres = await GetTaller(user? user.uid:'')
        setDato(talleres)
    }

    const conectToSap = async () => {
        // console.log(userdatos)
        if (datacred) { //Agregar condicion para conectar a sap de acuerdo al valor SAP en userdatos.
            // dispatch(addSapState('Conectando'))
            try {
                // TESTING: para produccion =>"datacred.CompanyDB" / testing => "datacred.CompanyDB_Test"
                let status = await Conect(datacred.CompanyDB, datacred.UserName, datacred.Password, datacred.BaseURL, Sl)
                // let status = await Conect(datacred.CompanyDB_Test, datacred.UserName, datacred.Password, datacred.BaseURL, Sl)
                
                if (status === 200) {
                    dispatch(addSapInfo('SAP'))
                    conexion();
                    // dispatch(addSapConnected(true))
                    console.log("Conectado con SAP")
                } else {
                    console.log('Fallo en la conexion con SAP')
                    dispatch(addSapInfo('Desconectado'))
                }
            } catch (error) {
                console.log('Fallo en la conexion con SAP')
                console.log(error)
            }
        }
    }

     // Conectar
    const conexion = () => {
        // Iniciar temporizador para desconexión después de 30 minutos (1800000 milisegundos)
        setTimeout(desconectar, 1800000); // Desconectar después de 30 minutos
    }
  
    // Desconectar
    const desconectar = () => {
        dispatch(addSapInfo('Desconectado'));
    }
   
    useEffect(() =>{
        data()
    },[user])

    useEffect(() =>{
        conectToSap()
    },[datacred])

    

    if(user && dato[0]){
        return(
            <>
            <div className='col  d-flex' id='navbar1600'  style={{marginTop:"25px",borderBottomColor:"#ecb74c", borderBottom:"6px solid transparent"}}  >
                  <div className="collapse navbar-collapse " id="navbarNav" >

                    <ul className="navbar-nav ">
                        <li className="nav-item">
                            {dato && dato[0].Permiso === "Admin"?<Link className="nav-link hogar" to="/facturacion"style={{width:"10rem"}}>Facturacion</Link>:""}
                        </li>
                        <li className="nav-item">
                            {dato && dato[0].Permiso === "Admin" || dato[0].Permiso === "Garante"?<Link className="nav-link cocina" to="/ordenes"style={{width:"10rem"}}>Ordenes</Link>:""}
                        </li>
                        <li className="nav-item dropdown  "  style={{fontSize:"18px" }} >
                                <button className="nav-link nav-item movilidad " data-bs-toggle="dropdown" aria-expanded="false" style={{width:"10rem"}}>
                                    Precios
                                </button> 
                                <ul className="dropdown-menu dropdown-menu-light">
                                    <li className="nav-item">
                                        {dato && dato[0].Permiso === "Admin" || dato[0].Permiso === "Garante"?<Link className="nav-link climatizacion" to="/precios"style={{width:"10rem"}}>Lista de Precios</Link>:""}
                                    </li>
                                    <li className="nav-item">
                                        {dato && dato[0].Permiso === "Admin" || dato[0].Permiso === "Garante"?<Link className="nav-link climatizacion" to="/manodeobra"style={{width:"10rem"}}>Mano de obra</Link>:""}
                                    </li>
                                </ul>
                        </li>
                        <li className="nav-item">
                            {dato && dato[0].Permiso === "Admin"|| dato[0].Permiso === "Supervisor" || dato[0].Permiso === "Garante"?<Link className="nav-link linea-blanca" to="/retails"style={{width:"10rem"}}>Lista de retails</Link>:""}
                        </li>             
                        <li className="nav-item">
                            {dato && dato[0].Permiso === "Admin" || dato[0].Permiso === "Supervisor" || dato[0].Permiso === "Tecnico" || dato[0].Permiso === "Garante"?<Link className="nav-link agua-caliente" to="/remitos"style={{width:"10rem"}}>Remitos</Link>:""}
                        </li>
                        <li className="nav-item">
                            {dato && dato[0].Permiso === "Admin" || dato[0].Permiso === "Supervisor"|| dato[0].Permiso === "Tecnico" || dato[0].Permiso === "Garante"?<Link className="nav-link desayuno" to="/repuestos"style={{width:"10rem"}}>Repuestos</Link>:""}
                        </li>
                        <li className="nav-item  dropdown  "  style={{width:"10rem", fontSize:"18px" }} >
                                    <button className="nav-link nav-item movilidad " data-bs-toggle="dropdown" aria-expanded="false" style={{width:"10rem"}}>
                                    Taller
                                    </button> 
                                <ul className="dropdown-menu dropdown-menu-light">
                                    <li className='nav-item'>
                                        {dato && dato[0].Permiso === "Admin" || dato[0].Permiso === "Garante"?<Link className='nav-link hogar' to='/talleres'style={{width:"10rem"}}>Lista de Talleres</Link>:""}
                                    </li>
                                    <li className="nav-item">
                                        {dato && dato[0].Permiso === "Admin" || dato[0].Permiso === "Supervisor" || dato[0].Permiso === "Garante"?<Link className="nav-link hogar " to="/ordenesTaller"style={{width:"10rem"}}>Seguimiento Taller</Link>:""}
                                    </li>
                                    <li className="nav-item">
                                        {dato && dato[0].Permiso === "Admin" || dato[0].Permiso === "Supervisor" || dato[0].Permiso === "Tecnico" || dato[0].Permiso === "Garante"?<Link className="nav-link hogar " to="/nuevaorden" style={{width:"10rem"}}>Ordenes de taller</Link>:""}
                                    </li>
                                    <li className="nav-item">
                                        {dato && dato[0].Permiso === "Admin" || dato[0].Permiso === "Supervisor" || dato[0].Permiso === "Tecnico" || dato[0].Permiso === "Garante"?<Link className="nav-link hogar " to="/nuevaorden_old" style={{width:"10rem"}}>Ordenes de taller (old)</Link>:""}
                                    </li>

                                </ul>
                        </li>
                    </ul>
                            <div className="d-flex " id='connectandlogout' style={{marginTop:"10px"}} >

                                    <button className=" btn btn-light btn-sm"
                                            onClick = {handlelogout}>
                                            <img src={cerrarsesion} width="35rem" height="35rem"/>
                                    </button>
                                    
                                    <div   style={{lineHeight:'0.5rem'}}>
                                            { sap && sap.SapInfo === "SAP"?
                                            <>
                                                <img src={SapLogo} width="55rem" height="31rem"/>
                                                    <div className="status-circle online" style={{
                                                                                            width: '10px',
                                                                                            height: '10px',
                                                                                            borderRadius: '50%',
                                                                                            display: 'flex',
                                                                                            backgroundColor: 'green',
                                                                                            alignItems: 'flex-end'
                                                                                        }}>
                                                                                    
                                                </div>
                                            </>:
                                            <>
                                            <img src={SapLogoOff} onClick={conectToSap} width="55rem" height="31rem"/>
                                                    <div className="status-circle online" style={{
                                                                                            width: '10px',
                                                                                            height: '10px',
                                                                                            borderRadius: '50%',
                                                                                            display: 'flex',
                                                                                            backgroundColor: 'red',
                                                                                            alignItems: 'flex-end'
                                                                                            }}>

                                                    </div>
                                                </>
                                            }
                                    </div> 
                            </div>           
            {/* <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                <div className="flex-column ocultar-div" align="left" style={{lineHeight:'0.5rem'}}>
                    <p>{sap?sap.SapInfo:'Loading'}</p>
                </div> */}
                {/* <button className="btn btn-light btn-sm mx-1"
                            onClick = {handlelogout}
                            style={{margin: '0 auto', border:'none',backgroundColor: '#ffffff'}}>
                            <img src={cerrarsesion} width="35rem" height="35rem"/>
                </button> */}
            {/* </div>    */}
        </div>
        
    </div>
    </>
    )}
}