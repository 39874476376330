import React, {useState, useEffect} from 'react'
import {db} from "../components/firebase";
import {doc, getDoc, setDoc, addDoc, collection, 
    onSnapshot, query, deleteDoc, updateDoc, 
    arrayUnion, FieldValue, serverTimestamp, 
    getDocs, orderBy, startAt, endAt, where, FieldPath, limit} from "firebase/firestore";
import {AddRepuestoState, AddOrderState} from './dbfirebase'
import {useGeneral} from '../context/generalContext'

export const GenerarRemitos = () => {

    const [dato, setDatos] = useState('')
    const [filas, setFilas] = useState()
    const [remitosPendientes, setRemitosPendientes] = useState()
    const [filtrados, setFiltrados] = useState()
    const [nofiltrados, setNofiltrado] = useState()
    const [lotes, setLotes] = useState([]);
    const [filtro, setFiltro] = useState('');
    
    //Funcion que esta a la escucha de los datos en firebase, solo lo pude hacer correr aqui.
    useEffect(()=>{ 
        const actualdate = new Date()
        const prevdate = new Date(actualdate)
        prevdate.setDate(actualdate.getDate() - 180)

        let unsubscribe = null
        const fetchData = async () => {
            try {
                const docRef = query(collection(db, 'Ordenes'), where("TimeStamp", ">", prevdate));
                unsubscribe = await onSnapshot(docRef, (query) => {
                    let dato = [];
                    query.forEach(doc => {
                        const d = doc.data()
                        dato.push({...d})
                    });
                    
                    setDatos(dato)
                })
            } catch (error) {
                console.error(error)
            }
        }
        fetchData()
        
    },[])
    
    useEffect(()=>{
        let filas = [];
        const datosFiltrados = dato && dato.filter(item => {
            if (filtro === '') {
                return true; // Sin filtro, devuelve todos los elementos
            }
            return item.Transporte_cli === filtro; // Filtra según la categoría seleccionada
        });
        // console.log(filtro)
        // console.log(dato && dato.filter(item => item.Transporte_cli === 'Retirar'))
        // console.log(datosFiltrados)
        // Verificar si existe el campo "Repuestos" y si tiene valores
        datosFiltrados && datosFiltrados.forEach((dato)=>{
           
            if (dato.Repuestos && Array.isArray(dato.Repuestos) && dato.Repuestos.length > 0 && dato.Taller !== 'Goldmund (central)' && dato.EnviadoAGoldmund !== true) {
                
                dato.Repuestos.forEach((repuesto) => {
                    
                    if (repuesto.Lote !== undefined && repuesto.Lote !== null && repuesto.UltEstado === 'despacho') {
                        
                        const fila = {
                            Fecha: dato.Fecha,
                            UUID: dato.UUID,
                            Estado: repuesto.Estado,
                            UltEstado: repuesto.UltEstado,
                            FechaAct: new Date(repuesto.Ult_Act).toLocaleString(),
                            iddoc: dato.iddoc,
                            Taller: dato.Taller,
                            Falla: dato.Desperfecto_declarado,
                            Direccion: dato.Direccion,
                            Cliente: dato.Cliente,
                            CuitDni_cli: dato.CuitDni_cli,
                            PisoDept_cli: dato.PisoDept_cli,
                            Domicilio_cli: dato.Domicilio_cli,
                            Localidad_cli: dato.Localidad_cli,
                            CodigoPostal_cli: dato.CodigoPostal_cli,
                            Provincia_cli: dato.Provincia_cli,
                            Telefono_cli: dato.Telefono_cli,
                            Email_cli: dato.Email_cli,
                            Articulo: repuesto.Articulo,
                            Producto: dato.Articulo,
                            Descripcion: repuesto.Descripcion,
                            Cantidad: repuesto.cantidad,
                            Garantia: dato.Garantia,
                            Permiso: dato.Permiso,
                            NumFactura: dato.NumFactura,
                            FechaCompra: dato.FechaCompra,
                            RazonSocialCli: dato.RazonSocialCli,
                            Transporte: dato.Transporte,
                            Transporte_cli: dato.Transporte_cli,
                            OrdenTaller: dato.Num_Os,
                            Lote:repuesto.Lote,
                            id:repuesto.id||"",
                            FechaDespacho: repuesto.Estado[repuesto.Estado.length-1].despacho // Usando el mismo metodo se podria mostrar el resto de los estados con sus fechas
                        }
                        filas.push(fila);
                    }
                });
            }
        })
        datosFiltrados && datosFiltrados.forEach((dato)=>{
            if ((dato?.EnviadoAGoldmund === true && dato.Estado === 'Despacho') || (dato.Taller === 'Goldmund (central)' && (dato.Estado === 'Despacho' || dato.Estado === 'Retirar'))){
                const fila = {
                    Fecha: dato.Fecha,
                    UUID: dato.UUID,
                    Visible: true,
                    // Estado: repuesto.Estado,
                    // UltEstado: repuesto.UltEstado,
                    // FechaAct: new Date(repuesto.Ult_Act).toLocaleString(),
                    iddoc: dato.iddoc,
                    Taller: dato.Taller,
                    Falla: dato.Desperfecto_declarado,
                    Direccion: dato.Direccion,
                    Cliente: dato.Cliente,
                    CuitDni_cli: dato.CuitDni_cli,
                    PisoDept_cli: dato.PisoDept_cli,
                    Domicilio_cli: dato.Domicilio_cli,
                    Localidad_cli: dato.Localidad_cli,
                    CodigoPostal_cli: dato.CodigoPostal_cli,
                    Provincia_cli: dato.Provincia_cli,
                    Telefono_cli: dato.Telefono_cli,
                    Email_cli: dato.Email_cli,
                    // Articulo: repuesto.Articulo,
                    Producto: dato.Articulo,
                    Descripcion_producto: dato.Descripcion,
                    // Cantidad: repuesto.cantidad,
                    Garantia: dato.Garantia,
                    Permiso: dato.Permiso,
                    NumFactura: dato.NumFactura,
                    FechaCompra: dato.FechaCompra,
                    RazonSocialCli: dato.RazonSocialCli,
                    Transporte: dato.Transporte,
                    Transporte_cli: dato.Transporte_cli,
                    OrdenTaller: dato.Num_Os,
                    Lote:dato.iddoc,
                    // id:repuesto.id||"",
                    // FechaDespacho: repuesto.Estado[repuesto.Estado.length-1].despacho 
                }
          
            filas.push(fila);
            }
        })
        setFilas(filas)
        // setRemitosPendientes(filas.filter((etiqueta) => etiqueta?.UltEstado === "Autorizado"));
    },[dato, filtro])
    
    const handleClick = async (items) => {
        for (const it of items) {
            await AddRepuestoState(it, 'Despachado');
          }
      };

    const handleClickOrdenes = async (iddoc) => {
        await AddOrderState(iddoc, 'Despachado');
    };

    // Agrupar las filas por lote
    const filasAgrupadas = filas && filas.reduce((acc, item) => {
        if (!acc[item.Lote]) {
        acc[item.Lote] = [];
        }
        acc[item.Lote].push(item);
        return acc;
    }, {});

    useEffect(() =>{
        
        let filtrados = [];
        let nofiltrados = [];
        let lotes = [];
       
        if (filasAgrupadas) {
            Object.entries(filasAgrupadas).forEach(([lote, items]) => {
                // Filtramos los items que tienen Descripcion_producto
                const filteredItems = items.filter(i => i.Visible === true);
                // const nofilteredItems = items.filter(i => !i.Descripcion_producto);   
                // Añadir el lote actual a la lista de lotes
                lotes = [...lotes, lote];
                // Añadir los items filtrados a los arrays correspondientes
                if (filteredItems.length !== 0) {
                    filtrados = [...filtrados, ...filteredItems];

                } else {
                    nofiltrados = [...nofiltrados, ...items];
                }
            });

            // Actualizar los estados después de completar la iteración
            setFiltrados(filtrados);
            setNofiltrado(nofiltrados);
            setLotes(lotes); 
        }
    },[filas])

    console.log(filtrados)
    return(
        <div>
            <h3>Generar remitos</h3>
            <div className='col-lg-6 d-flex mb-3 contenedor' style={{ alignItems: 'center' }}>
                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>
                    Filtrar:
                </label>
                <select
                    name="Transporte_cli"
                    id="Transporte_cli"
                    className="form-control"
                    // onChange={handleForm1Change}
                    // style={{ maxWidth: '20rem', border: cliente.Transporte_cli.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                    // disabled={sended ? true : false}
                    onChange={(e) => setFiltro(e.target.value)}
                >
                    <option value="">Seleccione una opción</option>
                    <option value="Retira">Retira</option>
                    <option value="Correo Argentino">Correo Argentino</option>
                    <option value="Credifin">Credifin</option>
                    <option value="Cadeteria">Cadeteria</option>
                    <option value="Otros">Otros</option>
                </select>
            </div>
            <div className="container" style={{marginTop: '50px'}}>
            <div style={{marginBottom:"4rem", marginTop:"4rem"}}>
                    <hr></hr>
                    <h2>
                        Ordenes a remitir
                    </h2>
                </div>
            {/* {filasAgrupadas && Object.entries(filasAgrupadas).map(([lote, items], index) => {
                // Filtramos los items que tienen Descripcion_producto
                const filteredItems = items.filter(i => i.Descripcion_producto);
                const nofilteredItems = items.filter(i => !i.Descripcion_producto);   
                // Si no hay items filtrados, no renderizamos nada
                if (filteredItems.length !== 0) { */}
                {filtrados && filtrados.map((filteredItems, index) => {
                    return (
                    <div className="accordion" key={index}>
                        <div className="card">
                        <div className="row card-header" id={`heading-${index}`} 
                            role="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse-${index}`}
                            aria-expanded="true"
                            aria-controls={`collapse-${index}`}>
                            
                            <div className='col'>
                                <h2>
                                    {filteredItems.Taller}
                                </h2>
                                {/* Ver de colocar la fecha de actualizacion de repuesto con FechaAct */}
                                
                                <label>
                                    Fecha Despacho: {filteredItems.FechaDespacho} 
                                </label>

                                <p>
                                    Dirección: {filteredItems.Direccion}, Transporte: {filteredItems.Transporte}
                                </p>

                                {/* Puedes mostrar aquí cualquier información común para todas las filas del mismo lote */}
                            </div>
                            {(filteredItems?.Permiso === 'Garante' || filteredItems?.Taller === 'Goldmund (central)')?
                                <div className='col'>
                                    <h4>
                                        Cliente: {filteredItems.Cliente}
                                    </h4>
                                    {/* Ver de colocar la fecha de actualizacion de repuesto con FechaAct */}
                                    
                                    <label>
                                        Dni/Cuit: {filteredItems.CuitDni_cli} 
                                    </label>

                                    <p>
                                        Dirección: {filteredItems.Domicilio_cli}, Localidad: {filteredItems.Localidad_cli}, Provincia: {filteredItems.Provincia_cli} , PisoDept: {filteredItems.PisoDept_cli}, cp: {filteredItems.CodigoPostal_cli}
                                    </p>

                                    <p>
                                        Telefono: {filteredItems.Telefono_cli}, Email: {filteredItems.Email_cli}, Transporte: {filteredItems.Transporte_cli}
                                    </p>

                                    {/* Puedes mostrar aquí cualquier información común para todas las filas del mismo lote */}
                                </div>
                            :''}
                        </div>

                        <div
                            id={`collapse-${index}`}
                            className="collapse"
                            aria-labelledby={`heading-${index}`}
                            data-parent="#accordionExample"
                        >
                            <div className="card-body">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th>Artículo</th>
                                        <th>Descripción</th>
                                        <th>Orden/caso</th>
                                        <th>Id orden</th>
                                        <th>Garantia</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                        <td>{filteredItems.Producto}</td>
                                        <td>{filteredItems.Descripcion_producto}</td>
                                        <td>{filteredItems.OrdenTaller}</td>
                                        <td>{filteredItems.iddoc}</td>
                                        <td>{filteredItems.Garantia ? 'SI' : 'NO' }</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button className='btn btn-success col-12' onClick={() => {handleClickOrdenes(filteredItems.iddoc)}}>Generar</button>                            
                            </div>
                        </div>
                        </div>
                    </div>
                    
                )})}
                <div style={{marginBottom:"4rem", marginTop:"4rem"}}>
                    <hr></hr>
                    <h2>
                        Repuestos a remitir
                    </h2>
                </div>
                {filasAgrupadas && Object.entries(filasAgrupadas).map(([lote, items], index) => {
                    if (!items[0].Visible) {
                return (
                    <div className="accordion" key={`x-${index}`}>
                        <div className="card">
                        <div className="row card-header" id={`heading-x-${index}`} 
                            role="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse-x-${index}`}
                            aria-expanded="true"
                            aria-controls={`collapse-x-${index}`}>
                            
                            <div className='col'>
                                <h2>
                                    {items[0].Taller}
                                </h2>
                                {/* Ver de colocar la fecha de actualizacion de repuesto con FechaAct */}
                                
                                <label>
                                    Fecha Despacho: {items[0].FechaDespacho} 
                                </label>

                                <p>
                                    Dirección: {items[0].Direccion}, Transporte: {items[0].Transporte}
                                </p>

                                {/* Puedes mostrar aquí cualquier información común para todas las filas del mismo lote */}
                            </div>
                            {(items[0]?.Permiso === 'Garante' || items[0]?.Taller === 'Goldmund (central)')?
                                <div className='col'>
                                    <h4>
                                        Cliente: {items[0].Cliente}
                                    </h4>
                                    {/* Ver de colocar la fecha de actualizacion de repuesto con FechaAct */}
                                    
                                    <label>
                                        Dni/Cuit: {items[0].CuitDni_cli} 
                                    </label>

                                    <p>
                                        Dirección: {items[0].Domicilio_cli}, Localidad: {items[0].Localidad_cli}, Provincia: {items[0].Provincia_cli} , PisoDept: {items[0].PisoDept_cli}, cp: {items[0].CodigoPostal_cli}
                                    </p>

                                    <p>
                                        Telefono: {items[0].Telefono_cli}, Email: {items[0].Email_cli}, Transporte: {items[0].Transporte_cli}
                                    </p>

                                    {/* Puedes mostrar aquí cualquier información común para todas las filas del mismo lote */}
                                </div>
                            :''}
                        </div>

                        <div
                            id={`collapse-x-${index}`}
                            className="collapse"
                            aria-labelledby={`heading-x-${index}`}
                            data-parent="#accordionExample"
                        >
                            <div className="card-body">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th>Artículo</th>
                                        <th>Descripción</th>
                                        <th>Cantidad</th>
                                        <th>Garantia</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {items.map((item, idx) => (
                                        <tr key={idx}>
                                        <td>{item.Articulo}</td>
                                        <td>{item.Descripcion}</td>
                                        <td>{item.Cantidad}</td>
                                        <td>{item.Garantia ? 'SI' : 'NO' }</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                <button className='btn btn-success col-12' onClick={() => {handleClick(items)}}>Generar</button>
                            </div>
                        </div>
                        </div>
                    </div>
                    )}})}
                {/* )})} */}
            
            </div>
        </div>
    )
}