import React, {useState, useEffect} from 'react'
import {Table} from './table'
import {columnsRemitos} from './columns';
import {GetRetaillist, NewRemito, GetRemitoslist} from './dbfirebase'
import Autocomplete from 'react-google-autocomplete';
import { ToastContainer, toast } from 'react-toastify';
import {Ayuda} from './Help'
import { useSelector } from 'react-redux';
import { TarjetaAcordeon } from './tarjetas';
import excel from '../images/excel.png'
import { ModalDescargaExc } from './ModalDescargaExc';

export const ListaRemitos_old = () => {
    
    const [user, setUser] = useState('');
    const [dato, setDato] = useState()
    const [remito, setRemito] = useState({
        Cuit:'',
        Domicilio:'',
        Email:'',
        Nombre:'',
        RazonSocial:'',
        Telefono:'',
        Remito:'',
        Email:user,
    })
    const [retail, setRetail] = useState([])
    const [rem, Rem] = useState()
    const [filtroNombre, setFiltroNombre] = useState('');
    const [filtroRemito, setFiltroRemito] = useState('');
    const [filtrando,setFiltrando] = useState('');   
    const [cantidades,setCantidades] = useState()
    const ordertaller = useSelector(state => state.tallerInfo); 
    const userdatos = useSelector(state => state.userInfo)
    

  
    const userEmail = () => {
        const email = userdatos.map(data => data.Email);
        const lengthEmail = email.length > 0 ? userdatos[0].Email : '-';
        setUser(email);
    };
   
    useEffect(() => {
        userEmail(); 
    }, [userdatos]);
    
    const data = async() =>{ 
        const retail = await GetRetaillist()
        setRetail(retail)
    }

    const datos = async () => {
        const remitos = await GetRemitoslist();
        if (remitos) {
           

            const dat = remitos.flatMap((retail) => {
             
                if (retail.Remitos) {
                    return retail.Remitos.map((remito) => {


                        const numRemitoFechaMap = {};
                        ordertaller.forEach(order => {
                            numRemitoFechaMap[order.NumRemito] = order.Fecha;
                        });

                        const fechaRemito = numRemitoFechaMap[remito.Remito]
                        
                        const garantias = ordertaller
                                    .filter(order => order.NumRemito === remito.Remito)
                                    .map(garantia => garantia.Garantia ); 

                        
                        // Contar la cantidad de garantías y sin garantías
                        const garantiasConGarantia = garantias.filter(garantia => garantia);
                        const garantiasSinGarantia = garantias.filter(garantia => !garantia);


                       // Guardar las cantidades en el objeto de conteo
                        const objetoRetornado = {
                            Cuit: retail.Cuit,
                            Nombre: retail.Nombre,
                            Remito: remito.Remito,
                            Bultos: remito.Bultos,
                            Fecha: remito.Fecha.seconds,
                            Trabajado: remito.Trabajado,
                            Creator:remito.Email,
                            Garantia: {
                                // Asignar las longitudes de garantías con y sin garantía
                                conGarantia: garantiasConGarantia.length,
                                sinGarantia: garantiasSinGarantia.length,
                                // Aquí se asignan todos los valores de garantía
                            },  
                            Ult_Fecha:fechaRemito || "-",
                            Creator:remito.Email || "-",
                            id:retail.id,
                            remitoSalida:remito.remitoSalida
                        
                        };
                        return objetoRetornado;
                    });
                }
            });
                
           //  console.log("Conteo de garantías:", dat);
    
            // Filtro
            // Filtra los datos antes de que se reendericen en la tabla
            const firstLevelFilter = dat.filter((datos) =>
                datos.Nombre.toLowerCase().includes(filtroNombre.toLowerCase())
            );
    
            const secondLevelFilter = firstLevelFilter.filter((datos) =>
                datos.Remito.includes(filtroRemito)
            );
    
            setFiltrando(firstLevelFilter);
            setDato(secondLevelFilter);
        }
    };
         
    useEffect(() => {
        data();
        datos();
    }, [filtroNombre, filtroRemito]);


   // console.log(dato)
    const filtrarPorNombre = (retail, Nombre, item) => {
        const result = retail.filter(dato => dato.Nombre === Nombre);
        return result.length > 0 ? result[0][item] : null;
    }

    // const ClearForm = () => { 
    //     document.getElementById("Form").reset();
    // }

    
    //console.log(dato);
  

                             
        
    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setRemito((prevState) => ({
            ...prevState,
            [name]: value,
            Cuit: document.getElementById("Cuit").value,
            Domicilio: document.getElementById("Domicilio").value,
            Telefono: document.getElementById("Telefono").value,
            Email: document.getElementById("Email").value,
            RazonSocial: document.getElementById("RazonSocial").value,
            Email:user // Usar el valor del estado user como User,,
            
        }));
    };
    
    const handleFormSubmit = async() => {
        
        if (Object.keys(remito).some(key => remito[key] === "")) {
            toast.error('Debe completar todos los campos de usuario',{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true
            })
        } else {
            try {
                const upload = await NewRemito(remito);
                console.log(remito)
                data();
                datos();
                document.getElementById("Form").reset();

            } catch(error) {
                toast.error('Ocurrió un error, vuelva a intentarlo',{
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true
                })
                data();
                datos();
                document.getElementById("Form").reset();
                
            }
        }          
    }

        
       

    return(
        <div>
                
            <div className="row">
            <h3 className='col-md-2 p-0' style={{ display: 'flex', width:'auto', justifyContent: window.innerWidth < 768 ? 'start' : 'end', alignItems: 'center' }}>Remitos (VERSION VIEJA)</h3>
                    <Ayuda section="remitos"/>
                 <ToastContainer/>
            </div>


            
            <div className='card '  id='forum-remito' style={{marginTop:'50px'}}>
                
            <h4 className='d-flex justify-content-center'style={{marginTop:'50px'}}>Registrar Remito</h4>
                
                <form id='Form'  style={{margin:'50px '}}>
                    <div className="row " style={{margin:'0 25px 0 25px '}}>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Nombre:</label>
                                <input 
                                    type = "text"
                                    name = "Nombre"
                                    className = "form-control"
                                    placeholder = "Nombre de fantasia"
                                    id = "Nombre"
                                    list='nombre'
                                    autoFocus
                                    autoComplete = "off"
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    />
                                    <datalist id="nombre">
                                        {retail.map((retail, index) => (
                                            <option key={`${retail.Nombre}-${index}`} value={retail.Nombre}>
                                            {retail.Nombre}    
                                            </option>))
                                        }
                                    </datalist>
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Cuit/Dni:</label>
                                <input 
                                    type = "text"
                                    name = "Cuit"
                                    className = "form-control"
                                    placeholder = "Cuit"
                                    id = "Cuit"
                                    autoFocus
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    disabled={true}
                                    value={filtrarPorNombre(retail, remito.Nombre, "Cuit") || ''}
                                    />
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Domicilio:</label>
                                <input 
                                    type = "text"
                                    name = "Domicilio"
                                    className = "form-control"
                                    placeholder = "Domicilio"
                                    id = "Domicilio"
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    disabled={true}
                                    value={filtrarPorNombre(retail, remito.Nombre, "Domicilio") || ''}
                                />
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Razon Social:</label>
                                <input 
                                    type = "text"
                                    name = "RazonSocial"
                                    className = "form-control"
                                    placeholder = "Razon Social"
                                    id = "RazonSocial"
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    disabled={true}
                                    value={filtrarPorNombre(retail, remito.Nombre, "RazonSocial") || ''}
                                    />
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Telefono:</label>
                                <input 
                                    type = "text"
                                    name = "Telefono"
                                    className = "form-control"
                                    placeholder = "Telefono"
                                    id = "Telefono"
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    disabled={true}
                                    value={filtrarPorNombre(retail, remito.Nombre, "Telefono") || ''}
                                    />  
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Email:</label>
                                <input 
                                    type = "text"
                                    name = "Email"
                                    className = "form-control"
                                    placeholder = "Email"
                                    id = "Email"
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    disabled={true}
                                    value={filtrarPorNombre(retail, remito.Nombre, "Email") || ''}
                                    />
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>N° Remito:</label>
                                <input 
                                    type = "text"
                                    name = "Remito"
                                    className = "form-control"
                                    placeholder = "Numero de remito"
                                    id = "Remito"
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    autoComplete = "off"
                                    />
                        </div>
                        <div className='col-lg-6  d-flex ms-auto mb-3' style={{alignItems: 'center' }}>
                            <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Bultos:</label>
                                <input 
                                    type = "number"
                                    name = "Bultos"
                                    className = "form-control"
                                    placeholder = "Bultos"
                                    id = "Bultos"
                                    onChange={handleFormChange}
                                    style={{minWidth:'10rem', maxWidth:'19rem'}}
                                    autoComplete = "off"
                                    />
                        </div>
                    </div>    
                </form>

                <div className='d-flex justify-content-center col-12' style={{margin:'20px'}}>
                    <button type="submit" onClick={handleFormSubmit} className='col-6 btn btn-success w-25'>Guardar</button>    
                </div>
            </div>
               


        <div className= 'row d-flex justify-content-center'style={{marginTop: '150px'}}>
            <div className='remito-filter' style={{paddingLeft:'30px'}}>
            <span style={{ paddingLeft: '30px' }}>
                    Buscar Nombre:
                    <input
                        id="nombreOptions"
                        value={filtroNombre || ''}
                        onChange={(e) => setFiltroNombre(e.target.value)}
                        style={{ width: '15rem' }}
                        list="nombreOptionsList" // Asociar el datalist al input
                    />
                    <datalist id="nombreOptionsList"> {/* Cambiar el ID del datalist */}
                        {dato && dato.length > 0 &&
                            [...new Set(dato.map(item => item.Nombre))]
                                .map((Nombre, index) => (
                                    <option key={index} value={Nombre} />
                                ))
                        }
                    </datalist>
                </span>
                <span style={{ paddingLeft: '30px' }}>
                    Buscar N º Remito:
                    <input
                        id="remitoOptions"
                        value={filtroRemito || ''}
                        onChange={(e) => setFiltroRemito(e.target.value)}
                        style={{ width: '15rem' }}
                        list="remitoOptionsList" // Asociar el datalist al input
                    />
                    <datalist id="remitoOptionsList"> {/* Cambiar el ID del datalist */}
                        {dato && dato.length > 0 &&
                            [...new Set(dato.map(item => item.Remito))]
                                .map((Remito, index) => (
                                    <option key={index} value={Remito} />
                                ))
                        }
                    </datalist>
                </span>

            <ModalDescargaExc listaOrdenes={dato}/>
            <div className='d-flex justify-content-end'>
                    <button type="button" className="btn btn-outline-secondary"  
                            data-bs-toggle="modal"
                            data-bs-target="#modalTableDowload" 
                            >
                                <img src={excel} width="50rem" height="50rem" />
                            </button>  
                </div>

            </div>
                {dato && <Table listaOrdenes={dato} source={"orden"} settingcolumns={columnsRemitos} showTimestamp={true} rows={dato.length} showQrCode={true} showEditRemito={true} filter={true}/>}
            </div>              
        </div>
    )
}