import React from "react";

export const ErrorModal = ({ errorMessage, onClose }) => {
  if (!errorMessage) return null; // Si no hay error, no renderiza nada

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "5px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
          maxWidth: "400px",
          textAlign: "center",
        }}
      >
        <p>{errorMessage}</p>
        <button
          onClick={onClose}
          style={{
            marginTop: "10px",
            padding: "10px 20px",
            border: "none",
            backgroundColor: "#007BFF",
            color: "#fff",
            cursor: "pointer",
            borderRadius: "5px",
          }}
        >
          Cerrar
        </button>
      </div>
    </div>
  );
};