import {createContext, useContext} from 'react';
import axios from "axios";
import {SLCred} from '../components/dbfirebase'
import {useAuth} from "../context/authcontext";
import {GetPerfil} from '../components/dbfirebase'

// Creamos un contexto 
export const GeneralContext = createContext();

//Funcion almacena los datos que retorne el contexto
export const useGeneral = () =>{
    const context = useContext(GeneralContext)
    return context
};

//Funcion que proveera al contexto de los datos de logueo
export function GeneralProvider({children, logged}){
    const { user } = useAuth();

    let userData = {};
    // let datacred = {};
    let Sl = () => {};
    
    if (logged) {
        userData = GetPerfil(user ? user.uid : '');

        // datacred = SLCred();

        Sl = (BaseURL) => {
            const ServiceLayer = axios.create({
                withCredentials: true,
                baseURL: BaseURL,
                responseType: 'json',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            
            return ServiceLayer;
        };
    }
    
    return (
        <GeneralContext.Provider value={ {Sl, userData}}>
            {children}
        </GeneralContext.Provider>
        );

}


