import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  SapInfo: [], // Aquí almacenarás los datos de productos
  sessionID: null,
};

const sapInfoSlice = createSlice({
  name: 'SapInfo',
  initialState,
  reducers: {
    addSapInfo: (state, action) => {
      state.SapInfo = action.payload; // Actualiza los datos de productos
    },
    setSessionID: (state, action) => {
      state.sessionID = action.payload; // Actualiza el sessionID
    },
  },
});

export const { addSapInfo, setSessionID} = sapInfoSlice.actions;
export default sapInfoSlice.reducer;