import React, {useState} from "react";
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom'
import { Navigate } from "react-router-dom";
import {ProtectedRoute} from './components/protectedroutes'
import {Navbar} from './components/navbar'
import {Login} from './components/login'
import {Micuenta} from './components/micuenta'
import {ListaTalleres} from './components/talleres'
import {ListaPrecios} from './components/listaprecios'
import {ListaRetails} from './components/retails'
import {Ordenes} from './components/ordenes'
import {AuthProvider} from './context/authcontext'
import {NewOrder} from './components/neworders'
import {NewOrder_old} from './components/neworders_old'
import {GeneralProvider} from './context/generalContext'
import {OrdenesTaller} from './components/ordenesTaller'
import {signOut} from 'firebase/auth';
import {auth} from "./components/firebase";
import {ListaRemitos} from "./components/remitos"
import {ListaRemitos_old} from "./components/remitos_old"
import {PedidoRepuestos} from "./components/pedidorepuestos"
import {EditOrder} from "./components/EditOrder"
import {QRScanner} from "./components/ScannerQR"
import {PedidosClientes} from "./components/pedidosclientes"
import {Manual} from "./components/Manual_ayuda"
import {Despacho} from "./components/despacho"
import {GenerarRemitos} from "./components/GenerarRemitos"
import {GenerarAutorizaciones} from "./components/GenerarAutorizaciones"
import { EditRetail } from "./components/ModificarRetail";
import { EditTaller } from "./components/ModificarOrdenTaller";
import { Facturacion } from "./components/Facturacion";
import { ModificarRemito } from "./components/ModificarRemito";
import { Similarities } from "./components/Similarities"
import { ListaManodeObra } from "./components/costoMdo"

function App() {
  const [logged, setLogged] = useState()
  // const handleBeforeUnload = (event) => {
  //   // Realiza aquí las acciones de limpieza al cerrar la página
  //   // borra los datos del usuario logeado o restablecer el estado de la aplicación
  //   signOut(auth)
  // };
  //window.addEventListener('beforeunload', handleBeforeUnload);

  return (
    <div className="App " >
    <AuthProvider setLogged={setLogged}>
      <GeneralProvider logged = {logged}>
        <Router>
          <Navbar/>
            <div className="container p-4 body" style={{ minHeight: 'calc(100vh - 149px)', maxWidth: "90%"}}>
              <Routes>
                <Route path = "/" element = {logged ? <Navigate to="/micuenta" /> : <Login />}/>
                <Route path = "/micuenta" element = {
                    <ProtectedRoute>
                      <Micuenta/>
                    </ProtectedRoute>}/>
                <Route path = "/ordenes" element = {
                    <ProtectedRoute>
                      <Ordenes/>
                    </ProtectedRoute>}/>
                <Route path = "/pedidoclientes" element = {
                    <ProtectedRoute>
                      <PedidosClientes/>
                    </ProtectedRoute>}/>
                <Route path = "/talleres" element = {
                    <ProtectedRoute>
                      <ListaTalleres/>
                    </ProtectedRoute>}/>
                <Route path = "/precios" element = {
                    <ProtectedRoute>
                      <ListaPrecios/>
                    </ProtectedRoute>}/>
                <Route path = "/retails" element = {
                    <ProtectedRoute>
                      <ListaRetails/>
                    </ProtectedRoute>}/>
                <Route path = "/analisisdesimilaridad" element = {
                    <ProtectedRoute>
                      <Similarities/>
                    </ProtectedRoute>}/>
                <Route path="/retails/modificaciones/:id" element={
                  <ProtectedRoute>
                    <EditRetail/>
                  </ProtectedRoute>} />
                <Route path = "/nuevaorden" element = {
                    <ProtectedRoute>
                      <NewOrder/>
                    </ProtectedRoute>}/>
                <Route path = "/nuevaorden_old" element = {
                    <ProtectedRoute>
                      <NewOrder_old/>
                    </ProtectedRoute>}/>
                <Route path = "/ordenesTaller" element = {
                    <ProtectedRoute>
                      <OrdenesTaller/>
                    </ProtectedRoute>}/>
                <Route path="/ordenesTaller/modificar/:iddoc" element = {
                    <ProtectedRoute>
                      <EditTaller/>
                    </ProtectedRoute>}/>    
                <Route path="/remitos/modificar/:id" element = {
                    <ProtectedRoute>
                      <ModificarRemito/>
                    </ProtectedRoute>}/> 
                <Route path = "/remitos" element = {
                    <ProtectedRoute>
                      <ListaRemitos/>
                    </ProtectedRoute>}/>
                <Route path = "/remitos_old" element = {
                    <ProtectedRoute>
                      <ListaRemitos_old/>
                    </ProtectedRoute>}/>
                <Route path = "/repuestos" element = {
                    <ProtectedRoute>
                      <PedidoRepuestos/>
                    </ProtectedRoute>}/>
                <Route path="/ordenes/:id" element={
                    <ProtectedRoute>
                      <EditOrder/>
                    </ProtectedRoute>} />
                
                <Route path = "/scanner" element={
                    <ProtectedRoute>
                      <QRScanner/>
                    </ProtectedRoute>} />
                <Route path = "/manual" element={
                    <ProtectedRoute>
                      <Manual/>
                    </ProtectedRoute>} />
                <Route path = "/despacho" element={
                    <ProtectedRoute>
                      <Despacho/>
                    </ProtectedRoute>} />
                <Route path = "/confremitos" element={
                    <ProtectedRoute>
                      <GenerarRemitos/>
                    </ProtectedRoute>} />
                <Route path = "/autorizaciones" element={
                    <ProtectedRoute>
                      <GenerarAutorizaciones/>
                    </ProtectedRoute>} />
                <Route path = "/facturacion" element={
                    <ProtectedRoute>
                      <Facturacion/>
                    </ProtectedRoute>} />
                <Route path = "/manodeobra" element={
                    <ProtectedRoute>
                      <ListaManodeObra/>
                    </ProtectedRoute>} />
              </Routes>
            </div>
            <footer className="bottom-0 w-100 heightFooter text-center p-3" style={{ backgroundColor: "#1974AA", borderTop: "5px solid #ddd" }}>
              {/* <small style={{ color: 'white' }}>&copy; Goldmund&reg; | Todos los Derechos reservados.</small> */}
                <div className="row">
                  <div className="col d-none d-sm-block">
                  {/* Espacio vacío en la primera parte */}
                  </div>
                  <div className="col d-flex justify-content-center">
                    <small style={{ color: 'white' }}>&copy; Goldmund&reg; | Todos los Derechos reservados.</small>
                  </div>
                  <div className="col d-flex justify-content-end">
                    <Link to="/manual" className="btn btn-primary">Manual</Link>
                  </div>
              </div>
            </footer>
        </Router>
      </GeneralProvider>
    </AuthProvider>
    </div>
  );
}

export default App;

