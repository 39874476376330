import React, {useEffect, useState, useRef} from 'react'
import {Actualizar, GetOrder,updatePrecioTaller} from './dbfirebase'


export const ModalPrecio = (row, {setUpdate}) => {
    
    const[motivo, setMotivo] = useState()
    const[validated, setValidated] = useState(false)

    const OrderRef = useRef(null)
    
    const closeModal = () => {
        const modal = document.getElementById('Modal3');
        const backdrop = document.querySelector('.modal-backdrop');
        
        if(OrderRef.current.classList.contains('show')){           
            modal.classList.remove('show');
            modal.style.display = 'none'
            document.body.classList.remove('modal-open');
            modal.classList.add('show');
        }
        if (backdrop) {
            backdrop.remove();
        }
        document.body.style.overflow = 'auto';
        setValidated(false)
        
    };

    const handleCloseModal = () => {
         closeModal();
         
    };

    // window.history.pushState({}, '', '');

    useEffect(() => {

        const handlePopstate = () => {
            if (OrderRef.current.classList.contains('show')) {
                closeModal()
            }
        };
      
        window.addEventListener('popstate', handlePopstate);
      
        return () => {
          
            window.removeEventListener('popstate', handlePopstate);
        };
        
    },[]);
    
    const dataId = async (row) =>{
        const textareaValue = document.getElementById("Precio").value
        const objeto = JSON.parse(localStorage.getItem('Fila'))
        const newstate = await updatePrecioTaller(objeto.Categoria, objeto.Articulo, objeto.Codigo, parseFloat(textareaValue)/100);
        row.setUpdate(true)
        document.getElementById("Precio").value = ''
        closeModal()
    }

    useEffect(()=>{
        localStorage.setItem('Fila',JSON.stringify(row.rowdata))
    },[row.rowdata])
    
    return (
        <div className="modal"
            id="Modal3"
            tabIndex={-1}
            aria-labelledby="Modal3"
            aria-hidden="true"
            style={{marginBottom:'20px' ,marginTop:'20px', overflow: "auto"}}
            ref={OrderRef}>
            <div className="modal-dialog modal-fullscreen-lg-down modal-lg">
                <div className='container modal-content' style={{ overflow: "auto",height:'23rem'}} >
                    <button type="button" className='btn-close' onClick={()=>handleCloseModal()}></button>
                    <h2>Nuevo Precio</h2>
                    <p>Ingrese el precio del repuesto para taller.</p>
                        <div className=' col-12 d-flex justify-content-center'>
                            <input
                                 type = "text"
                                 name = "Precio"
                                 className = "form-control"
                                 placeholder = "Nuevo precio taller"
                                 id = "Precio"
                                 autoFocus
                                style={{marginTop:'20px', width:'100%'}}
                            />
                        </div>  
                        <div className=' col-12 d-flex justify-content-end'>    
                            <button className="btn btn-secondary" style={{height:'3rem', width:'7rem',marginTop:'20px' }} onClick={()=>{dataId(row)}}>
                                Confirmar
                            </button>
                        </div>
                        {/* {validated && 
                        <div>
                            <p>{validated?"Firma Valida":"Firma Invalida"}</p>
                            <img src={validated?check:''} width='55rem' height='55rem' alt='' />
                        </div>} */}
                </div>
            </div>
        </div>
    );
  };