import React, {useState, useEffect, useCallback} from 'react'
import {Table} from './table'
import {columnsRemitos} from './columns';
import {GetRetaillist, NewRemito, GetRemitoslist, GetClientList, addDevoluciones, SLCred, getDevoluciones} from './dbfirebase'
import Autocomplete from 'react-google-autocomplete';
import { ToastContainer, toast } from 'react-toastify';
import {Ayuda} from './Help'
import { useSelector } from 'react-redux';
import { TarjetaAcordeon } from './tarjetas';
import excel from '../images/excel.png'
import { ModalDescargaExc } from './ModalDescargaExc';
import {useGeneral} from '../context/generalContext'
import {ErrorModal} from "./Errormodal";
import _ from "lodash";


export const ListaRemitos = () => {
    
    const [user, setUser] = useState('');
    const [dato, setDato] = useState()
    const [remito, setRemito] = useState({})
    const [retail, setRetail] = useState([])
    const [rem, Rem] = useState()
    const [filtroNombre, setFiltroNombre] = useState('');
    const [filtroRemito, setFiltroRemito] = useState('');
    const [filtrando,setFiltrando] = useState('');   
    const [cantidades,setCantidades] = useState()
    const [cliente, setCliente] = useState()
    const [productos, setProductos] = useState([{}]);
    const [batchList, setBatchList] = useState()
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);  // Estado de carga

    const ordertaller = useSelector(state => state.tallerInfo); 
    const userdatos = useSelector(state => state.userInfo)
    const sku = useSelector(state => state.productData.products)
    const sessionId = useSelector(state => state.SapInfo.sessionID)

    const {userData, Sl} = useGeneral()
    
    const userEmail = () => {
        const email = userdatos.map(data => data.Email);
        const lengthEmail = email.length > 0 ? userdatos[0].Email : '-';
        setUser(email);
    };
   
    useEffect(() => {
        userEmail(); 
    }, [userdatos]);
    
    const data = async() =>{ 
        const retail = await GetRetaillist()
        setRetail(retail)
    }

    const clientes = async(cuit) =>{
        // const client = await GetClientList(cuit) // Busca en firebase, reemplazamos por busqueda en SAP
        const client = await Sl("https://192.168.110.45:50000/b1s/v1").get(`/sml.svc/PV_CLIENTES_FULL?$filter = CUIT eq '${cuit}'`)
        
        setCliente(client.data.value)
    } 

    // const datos = async () => {
    //     const remitos = await GetRemitoslist();
    //     if (remitos) {
    //         const dat = remitos.flatMap((retail) => {   
    //             if (retail.Remitos) {
    //                 return retail.Remitos.map((remito) => {
    //                     const numRemitoFechaMap = {};
    //                     ordertaller.forEach(order => {
    //                         numRemitoFechaMap[order.NumRemito] = order.Fecha;
    //                     });
    //                     const fechaRemito = numRemitoFechaMap[remito.Remito]
    //                     const garantias = ordertaller
    //                                 .filter(order => order.NumRemito === remito.Remito)
    //                                 .map(garantia => garantia.Garantia ); 
    //                     // Contar la cantidad de garantías y sin garantías
    //                     const garantiasConGarantia = garantias.filter(garantia => garantia);
    //                     const garantiasSinGarantia = garantias.filter(garantia => !garantia);
    //                    // Guardar las cantidades en el objeto de conteo
    //                     const objetoRetornado = {
    //                         Cuit: retail.Cuit,
    //                         Nombre: retail.Nombre,
    //                         Remito: remito.Remito,
    //                         Bultos: remito.Bultos,
    //                         Fecha: remito.Fecha.seconds,
    //                         Trabajado: remito.Trabajado,
    //                         Creator:remito.Email,
    //                         Garantia: {
    //                             // Asignar las longitudes de garantías con y sin garantía
    //                             conGarantia: garantiasConGarantia.length,
    //                             sinGarantia: garantiasSinGarantia.length,
    //                             // Aquí se asignan todos los valores de garantía
    //                         },  
    //                         Ult_Fecha:fechaRemito || "-",
    //                         Creator:remito.Email || "-",
    //                         id:retail.id,
    //                         remitoSalida:remito.remitoSalida
    //                     };
    //                     return objetoRetornado;
    //                 });
    //             }
    //         });
    //         // Filtra los datos antes de que se reendericen en la tabla
    //         const firstLevelFilter = dat.filter((datos) =>
    //             datos.Nombre.toLowerCase().includes(filtroNombre.toLowerCase())
    //         );
    //         const secondLevelFilter = firstLevelFilter.filter((datos) =>
    //             datos.Remito.includes(filtroRemito)
    //         );
    //         setFiltrando(firstLevelFilter);
    //         setDato(secondLevelFilter);
    //     }
    // };

    const datos_dev = async () => {
        const remitos = await getDevoluciones();
        if (remitos) {
            const dat = remitos.flatMap((retail) => {
                if (retail.DocumentLines) {
                    return retail.DocumentLines.map((remito) => {
                        // const numRemitoFechaMap = {};
                        // ordertaller.forEach(order => {
                        //     numRemitoFechaMap[order.NumRemito] = order.Fecha;
                        // });
                        // const fechaRemito = numRemitoFechaMap[remito.Remito]
                        // const garantias = ordertaller
                        //             .filter(order => order.NumRemito === remito.Remito)
                        //             .map(garantia => garantia.Garantia ); 
                        // // Contar la cantidad de garantías y sin garantías
                        // const garantiasConGarantia = garantias.filter(garantia => garantia);
                        // const garantiasSinGarantia = garantias.filter(garantia => !garantia);
                        // Guardar las cantidades en el objeto de conteo
                        const objetoRetornado = {
                            Cuit: retail.Cuit,
                            Nombre: retail.CardName,
                            Remito: retail.FolioNumberFrom,
                            Producto: remito.ItemCode,
                            DocNumSAP: retail.DocNumSAP,
                            Cantidad: remito.Quantity,
                            Fecha: retail.Fecha,
                            Trabajado: remito.worked || 0,
                            Creator:retail.Email[0],
                            // Garantia: {
                            //     // Asignar las longitudes de garantías con y sin garantía
                            //     conGarantia: garantiasConGarantia.length,
                            //     sinGarantia: garantiasSinGarantia.length,
                            //     // Aquí se asignan todos los valores de garantía
                            // },  
                            // Ult_Fecha:fechaRemito || "-",
                            id:retail.id,
                            remitoSalida:remito.remitoSalida
                        };
                        return objetoRetornado;
                    });
                }
            });
            // Filtra los datos antes de que se reendericen en la tabla
            const firstLevelFilter = dat.filter((datos) =>
                datos.Nombre.toLowerCase().includes(filtroNombre.toLowerCase())
            );
            const secondLevelFilter = firstLevelFilter.filter((datos) =>
                datos.Remito.includes(filtroRemito)
            );
            setFiltrando(firstLevelFilter);
            setDato(secondLevelFilter);
        }
    };
         
    useEffect(() => {
        data();
        // datos();
        datos_dev()
    }, [filtroNombre, filtroRemito]);

   // console.log(dato)
    const filtrarPorNombre = (retail, Nombre, item) => {
        console.log("ejecutnado filtrar por nombre")
        const result = retail.filter(dato => dato.Nombre === Nombre);
        return result.length > 0 ? result[0][item] : null;
    }

    // const ClearForm = () => { 
    //     document.getElementById("Form").reset();
    // }
    
    //console.log(dato);

    const debouncedSetValue = useCallback(
        _.debounce((val) => setRemito(val), 300),
        []
    );
  
    const handleFormChange = (e) => {
        const { name, value } = e.target;
        debouncedSetValue({[name]: value});
        // setRemito((prevState) => ({
        //     ...prevState,
        //     [name]: value,
        //     Cuit: prevState.Cuit, // document.getElementById("Cuit").value,
        //     Email:user, // Usar el valor del estado user como User,       
        // }));
    };
    
    const handleFormSubmit = async() => {
        setLoading(true);  // Activar el loading
        remito['Email'] = user
        remito['Cuit'] = cliente[0]?.CUIT
        remito['DocumentLines'] = productos
        remito['Address'] = cliente && cliente[0]?.Direccion_Entrega ||"-"
        // remito['Telefono'] = cliente && cliente[0]?.Telefono_1 ||"-"
        remito['CardCode'] = cliente && cliente[0]?.Cliente_ID ||"-"
        remito['CardName'] = cliente && cliente[0]?.Cliente ||"-"
        remito['FolioNumberFrom'] = document.getElementById("FolioNumberFrom").value || "-"
        remito['FolioNumberTo'] = document.getElementById("FolioNumberFrom").value || "-"
        remito['PointOfIssueCode'] = "4"
       

        if (Object.keys(remito).some(key => remito[key] === "")) {
            toast.error('Debe completar todos los campos de usuario',{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true
            })
        } else {
            try {
                const response = await Sl("https://192.168.110.45:50000/b1s/v1").post("/Returns", remito);
                // const upload = await NewRemito(remito); // Es posible que esta no la debamos usar ya que buscaremos por DocNum de SAP
               // Capturamos el número de documento desde la respuesta
                const docNum = response.data.DocNum; // Asegúrate de que "DocNum" sea la clave correcta en la respuesta
                // Agregamos el número de documento al objeto remito
                remito.DocNumSAP = docNum;
                remito.Estado = "open"
                remito.Fecha = new Date().getTime()
                // Enviamos el remito actualizado a Firebase
                await addDevoluciones(remito);
                data(); //Estas funciones son para actualizar datos
                datos_dev(); //Estas funciones son para actualizar datos
                document.getElementById("Form").reset();

                // Al finalizar el proceso, desactivamos el loading
                setLoading(false);
                toast.success(`Devolucion generada, ID:${docNum}`,{
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true
                })
            } catch(error) {
                setError(error.response.data.error.message.value)
                toast.error('Ocurrió un error, vuelva a intentarlo',{
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true
                })
                // data();
                // datos();
                // document.getElementById("Form").reset();
                // Al finalizar el proceso con error, desactivamos el loading
                setLoading(false);
            }
        } 
        // Actualiza el estado de productos para restablecer el BatchNumber en todos los productos
        const productosActualizados = productos.map(prod => ({
            ...prod,
            BatchNumbers: [{ BatchNumber: "" }]
        }));

    setProductos(productosActualizados);  // Actualiza el estado de productos         
    }

    const agregarProducto = () => {
        setProductos([...productos, { ItemCode: "", Quantity: ""}]);
    };

    const actualizarBatchNumber = (index, e) => {
        const { value } = e.target;
        const newProductos = [...productos];
        newProductos[index]["BatchNumbers"] = [
            {
                "BatchNumber": value, // Coloca aquí el BatchNumber deseado
                "Quantity": newProductos[index]["Quantity"], // Usa el valor de e.target (cantidad seleccionada)
            },
        ];
        setProductos(newProductos);
    };

    // const handleFetch = async () => {
    //     try {
    //       // Simulación de una consulta que retorna un error
    //       const response = {
    //         error: {
    //           code: -10,
    //           message: {
    //             lang: "en-us",
    //             value: "10001069 - Item PE-AS20 is inactive",
    //           },
    //         },
    //       };
    
    //       if (response.error) {
    //         throw new Error(response.error.message.value);
    //       }
    //     } catch (err) {
    //       setError(err.message); // Guardar el mensaje de error en el estado
    //     }
    //   };

    const handleProductoChange = async(index, e) => {
        const { name, value } = e.target;
        const newProductos = [...productos];
        // Asignar el nuevo valor al campo correspondiente
        // No actualices el campo BatchNumber en esta función
        if (name !== "BatchNumber") {
            newProductos[index][name] = value;
        }
        // Asegurar que el campo warehousecode esté definido con el valor "006"
        newProductos[index]["WarehouseCode"] = "006";
        // Revisar si tiene que ser seleccionado un lote.
        const urldata = `BatchNumberDetails?$filter = ItemCode eq '${newProductos[index]['ItemCode']}'`
        const res = await Sl("https://192.168.110.45:50000/b1s/v1").get(urldata)
        setBatchList(res.data.value)
        setProductos(newProductos);
    };

    const eliminarProducto = (index) => {
        // Solo ejecuta si el índice es mayor a 1  
        if (productos.length > 1) {
            const nuevosProductos = productos.filter((_, i) => i !== index);
            setProductos(nuevosProductos);
        }
    };
    

    return(
        <div>
                
            <div className="row">
            <h3 className='col-md-2 p-0' style={{ display: 'flex', width:'auto', justifyContent: window.innerWidth < 768 ? 'start' : 'end', alignItems: 'center' }}>Remitos</h3>
                    <Ayuda section="remitos"/>
                 <ToastContainer/>
            </div>
            {/* <button onClick={handleFetch}>Hacer consulta</button> */}
            {/* Mostrar el modal si hay un error */}
            <ErrorModal
                errorMessage={error}
                onClose={() => setError(null)} // Cerrar el modal
            />

            <div className='card '  id='forum-remito' style={{marginTop:'50px'}}>
                
            <h4 className='d-flex justify-content-center'style={{marginTop:'50px'}}>Registrar Remito</h4>
                
                <form id='Form'  style={{margin:'50px '}}>
                    <div className="row " style={{margin:'0 25px 0 25px '}}>
                        <div className="row ">
                        <div className='col-lg-6  d-flex ms-auto mb-3 d-flex justify-content-center' style={{alignItems: 'center' }}>
                                <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Cuit/Dni:</label>
                                    <div className="input-group" style={{ minWidth: '10rem', maxWidth: '19rem' }}>
                                        <input 
                                            type = "text"
                                            name = "Cuit"
                                            className = "form-control"
                                            placeholder = "Cuit"
                                            id = "Cuit"
                                            autoFocus
                                            onChange={handleFormChange}
                                            style={{minWidth:'10rem', maxWidth:'19rem'}}
                                            // disabled={true}
                                            // value={filtrarPorNombre(retail, remito.Nombre, "Cuit") || ''}
                                            />

                                        <button
                                            className="btn btn-primary"
                                            type="button"
                                            onClick={()=>{clientes(remito.Cuit)}} // Función que manejará la acción de búsqueda
                                            title="Buscar"
                                        >
                                            Buscar
                                        </button>
                                    </div>
                            </div>
                            <div className='col-lg-6  d-flex ms-auto mb-3 d-flex justify-content-center' style={{alignItems: 'center' }}>
                                <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Nombre:</label>
                                    <input 
                                        type = "text"
                                        name = "Nombre"
                                        className = "form-control"
                                        placeholder = "Nombre de fantasia"
                                        id = "Nombre"
                                        list='nombre'
                                        autoComplete = "off"
                                        onChange={handleFormChange}
                                        style={{minWidth:'10rem', maxWidth:'19rem'}}
                                        disabled={true}
                                        value = {cliente && cliente[0]?.Nombre_Fantasia||""}
                                        />
                            </div>
                            
                            <div className='col-lg-6  d-flex ms-auto mb-3 d-flex justify-content-center' style={{alignItems: 'center' }}>
                                <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Domicilio:</label>
                                    <input 
                                        type = "text"
                                        name = "Domicilio"
                                        className = "form-control"
                                        placeholder = "Domicilio"
                                        id = "Domicilio"
                                        onChange={handleFormChange}
                                        style={{minWidth:'10rem', maxWidth:'19rem'}}
                                        disabled={true}
                                        value = {cliente && cliente[0]?.Direccion_Entrega||""}
                                    />
                            </div>
                            <div className='col-lg-6  d-flex ms-auto mb-3 d-flex justify-content-center' style={{alignItems: 'center' }}>
                                <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Razon Social:</label>
                                    <input 
                                        type = "text"
                                        name = "RazonSocial"
                                        className = "form-control"
                                        placeholder = "Razon Social"
                                        id = "RazonSocial"
                                        onChange={handleFormChange}
                                        style={{minWidth:'10rem', maxWidth:'19rem'}}
                                        disabled={true}
                                        value = {cliente && cliente[0]?.Cliente||""}
                                        />
                            </div>
                            <div className='col-lg-6  d-flex ms-auto mb-3 d-flex justify-content-center' style={{alignItems: 'center' }}>
                                <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Telefono:</label>
                                    <input 
                                        type = "text"
                                        name = "Telefono"
                                        className = "form-control"
                                        placeholder = "Telefono"
                                        id = "Telefono"
                                        onChange={handleFormChange}
                                        style={{minWidth:'10rem', maxWidth:'19rem'}}
                                        disabled={true}
                                        value = {cliente && cliente[0]?.Telefono_1||""}
                                        />  
                            </div>
                            <div className='col-lg-6  d-flex ms-auto mb-3 d-flex justify-content-center' style={{alignItems: 'center' }}>
                                <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Sap ID:</label>
                                    <input 
                                        type = "text"
                                        name = "Email"
                                        className = "form-control"
                                        placeholder = "Email"
                                        id = "Email"
                                        onChange={handleFormChange}
                                        style={{minWidth:'10rem', maxWidth:'19rem'}}
                                        disabled={true}
                                        value={cliente && cliente[0]?.Cliente_ID||""}
                                        />
                            </div>
                            <div className='col-lg-12 d-flex ms-auto mb-3 d-flex justify-content-center' style={{alignItems: 'center', marginTop:'50px' }}>
                                <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>N° Remito:</label>
                                    <input 
                                        type = "text"
                                        name = "FolioNumberFrom"
                                        className = "form-control"
                                        placeholder = "Numero de remito"
                                        id = "FolioNumberFrom"
                                        onChange={handleFormChange}
                                        style={{minWidth:'10rem'}}
                                        autoComplete = "off"
                                        />
                            </div>
                            <div className='col-lg-12 d-flex ms-auto mb-3 d-flex justify-content-center' style={{alignItems: 'center', marginTop:'50px' }}>
                                <label className='d-none d-sm-block' style={{margin:'0 1rem',maxWidth:'10rem',minWidth:'6rem'}}>Comentarios:</label>
                                    <input 
                                        type = "text"
                                        name = "Comments"
                                        className = "form-control"
                                        placeholder = "Observaciones"
                                        id = "Comments"
                                        onChange={handleFormChange}
                                        style={{minWidth:'10rem'}}
                                        autoComplete = "off"
                                        />
                            </div>
                        </div>
                        <div className="card" style={{marginTop:'50px'}}>
                            {/* Campos de Producto y Cantidad */}
                            {productos.map((producto, index) => (
                                <div key={index} className="row card-body">
                                    <div className='col-lg-2 d-flex ms-auto d-flex justify-content-center' style={{ alignItems: 'center' }}>
                                        <label className='d-none d-sm-block' style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Producto:</label>
                                        <input
                                            type="text"
                                            name="ItemCode"
                                            className="form-control"
                                            id = "Articulo"
                                            list='articulo'
                                            placeholder="Producto"
                                            // value={producto.producto}
                                            onChange={(e) => handleProductoChange(index, e)}
                                            style={{ minWidth: '10rem', maxWidth: '19rem' }}
                                        />
                                        <datalist id="articulo">
                                        {sku &&
                                            sku.map(art => (
                                                <option key={art} value={art}>
                                                    {art}
                                                </option>
                                            ))
                                            }
                                        </datalist>
                                    </div>
                                    <div className='col-lg-2 d-flex ms-auto d-flex justify-content-center' style={{ alignItems: 'center' }}>
                                        <label className='d-none d-sm-block' style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Cantidad:</label>
                                        <input
                                            type="number"
                                            name="Quantity"
                                            className="form-control"
                                            placeholder="Cantidad"
                                            // value={producto.cantidad}
                                            onChange={(e) => handleProductoChange(index, e)}
                                            style={{ minWidth: '10rem', maxWidth: '19rem' }}
                                        />
                                    </div>

                                    <div className='col-lg-2 d-flex ms-auto d-flex justify-content-center' style={{ alignItems: 'center' }}>
                                        <label className='d-none d-sm-block' style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Precio:</label>
                                        <input
                                            type="number"
                                            name="Price"
                                            className="form-control"
                                            placeholder="Precio"
                                            // value={producto.cantidad}
                                            onChange={(e) => handleProductoChange(index, e)}
                                            style={{ minWidth: '10rem', maxWidth: '19rem' }}
                                        />
                                    </div>

                                    {/* Selector de Batch */}
                                    <div className='col-lg-2 d-flex ms-auto d-flex justify-content-center' style={{ alignItems: 'center' }}>
                                        <label className='d-none d-sm-block' style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Batch:</label>
                                        <select
                                            className="form-control"
                                            name="BatchNumber"
                                            value={productos[index]?.BatchNumbers?.[0]?.BatchNumber || ""}
                                            onChange={(e) => actualizarBatchNumber(index, e)}
                                            style={{ minWidth: '10rem', maxWidth: '19rem' }}
                                        >
                                             <option value="" disabled>Seleccionar batch</option>
                                                {productos[index]?.BatchNumbers?.[0]?.BatchNumber 
                                                    ? (
                                                        // Si hay un BatchNumber seleccionado, muestra solo esa opción
                                                        <option value={productos[index]?.BatchNumbers?.[0]?.BatchNumber}>
                                                            {productos[index]?.BatchNumbers?.[0]?.BatchNumber}
                                                        </option>
                                                    ) 
                                                    : (
                                                        // Si no hay un BatchNumber seleccionado, muestra todas las opciones
                                                        batchList?.map((obj, batchIndex) => (
                                                            <option key={batchIndex} value={obj.Batch}>
                                                                {obj.Batch}
                                                            </option>
                                                        ))
                                                    )
                                                }
                                        </select>
                                    </div>

                                    <div className="col-lg-2 d-flex justify-content-center ms-auto">
                                        <div className="mx-2">
                                            <button 
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={agregarProducto}
                                            >
                                                +
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                type="button"
                                                className="btn btn-danger"
                                                onClick={() => eliminarProducto(index)} // Llama a la función con el índice actual
                                            >
                                                &times;
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* Botón para agregar más productos */}
                        {/* <div className="col-lg-12 d-flex justify-content-center mt-3">
                            <button 
                                type="button"
                                className="btn btn-primary"
                                onClick={agregarProducto}
                            >
                                Agregar más productos al remito
                            </button>
                        </div> */}
                    </div>  
                </form>

                <div className='d-flex justify-content-center col-12' style={{margin:'20px'}}>
                <button
                    type="submit"
                    onClick={handleFormSubmit}
                    className="col-6 btn btn-success w-25"
                    disabled={loading}  // Desactiva el botón si está cargando
                >
                    {loading ? "Loading..." : "Guardar"}
                </button>   
                </div>
            </div>
               


        <div className= 'row d-flex justify-content-center'style={{marginTop: '150px'}}>
            <div className='remito-filter' style={{paddingLeft:'30px'}}>
            <span style={{ paddingLeft: '30px' }}>
                    Buscar Nombre:
                    <input
                        id="nombreOptions"
                        value={filtroNombre || ''}
                        onChange={(e) => setFiltroNombre(e.target.value)}
                        style={{ width: '15rem' }}
                        list="nombreOptionsList" // Asociar el datalist al input
                    />
                    <datalist id="nombreOptionsList"> {/* Cambiar el ID del datalist */}
                        {dato && dato.length > 0 &&
                            [...new Set(dato.map(item => item.Nombre))]
                                .map((Nombre, index) => (
                                    <option key={index} value={Nombre} />
                                ))
                        }
                    </datalist>
                </span>
                <span style={{ paddingLeft: '30px' }}>
                    Buscar N º Remito:
                    <input
                        id="remitoOptions"
                        value={filtroRemito || ''}
                        onChange={(e) => setFiltroRemito(e.target.value)}
                        style={{ width: '15rem' }}
                        list="remitoOptionsList" // Asociar el datalist al input
                    />
                    <datalist id="remitoOptionsList"> {/* Cambiar el ID del datalist */}
                        {dato && dato.length > 0 &&
                            [...new Set(dato.map(item => item.Remito))]
                                .map((Remito, index) => (
                                    <option key={index} value={Remito} />
                                ))
                        }
                    </datalist>
                </span>

            <ModalDescargaExc listaOrdenes={dato}/>
            <div className='d-flex justify-content-end'>
                    <button type="button" className="btn btn-outline-secondary"  
                            data-bs-toggle="modal"
                            data-bs-target="#modalTableDowload" 
                            >
                                <img src={excel} width="50rem" height="50rem" />
                            </button>  
                </div>

            </div>
                {dato && <Table listaOrdenes={dato} source={"orden"} settingcolumns={columnsRemitos} showTimestamp={true} rows={dato.length} showQrCode={true} showEditRemito={true} filter={true}/>}
            </div>              
        </div>
    )
}