import React, {useEffect, useState} from 'react';
import {doc, getDoc, setDoc, addDoc, collection, 
        onSnapshot, query, deleteDoc, updateDoc, 
        arrayUnion, FieldValue, serverTimestamp, 
        getDocs, orderBy, startAt, endAt, where, FieldPath, limit} from "firebase/firestore";
import {useAuth} from "../context/authcontext";
import {db} from "../components/firebase";
import { toast } from 'react-toastify';
import { getStorage, ref, getDownloadURL } from "firebase/storage"
import { SlackErrorHandle } from '../components/SlackErrorMsj'
import { setSessionID } from '../store/SapInfo/SapInfoSlice'


// export const GetOrders = (num) =>{

//     const actualdate = new Date()
//     const prevdate = new Date(actualdate)
//     prevdate.setDate(actualdate.getDate() - num)

//     let unsubscribe = null

//     return new Promise(async(resolve, reject) => {
//       const docRef = query(collection(db, 'Ordenes'), where("TimeStamp", ">", prevdate),  where("TimeStamp", ">", prevdate));
//       unsubscribe = await onSnapshot(docRef, (query) => {
//           let dato = [];
//           query.forEach(doc => {
//               const d = doc.data()
//               const i = {'Id': doc.id}
//               dato.push({...d, ...i})
//           });
//           resolve(dato)
//       }, reject)
//     })
//     .finally(() =>{
//         if (unsubscribe){
//             unsubscribe()
//         }
//     })

// }


export const Addworked = async (Cuit, remito) => {
  const docRef = query(collection(db, 'Remitos'), where("Cuit", "==", Cuit));
  try {
    const querySnapshot = await getDocs(docRef);
    const promises = querySnapshot.docs.map(async (doc) => {
      const remitosData = doc.data().Remitos;
      
      // Buscar el grupo existente con el mismo número de remito
      const existingGroup = remitosData.find((grupo) => grupo.Remito === remito);
      if (existingGroup) {
        // Agregar el nuevo remito al grupo existente
        if (existingGroup.hasOwnProperty('Trabajado')) { //En la base de datos de remitos la T es mayuscula
            existingGroup.Trabajado += 1;
        } else {
        // Si el campo "trabajado" no existe, asignar el valor 1
            existingGroup.Trabajado = 1;
        }
       
          // Actualizar el grupo en remitosData con el nuevo valor de trabajado
          const updatedRemitosData = remitosData.map((grupo) => {
            if (grupo.Remito === remito) {
              return existingGroup;
            }
            return grupo;
          });

          try {
            // Obtener la referencia al documento específico
            const specificDocRef = doc.ref
            const existingData = doc.data();
            const updatedData = {...existingData, Remitos: updatedRemitosData };
            // Actualizar el documento con los datos modificados
            await setDoc(specificDocRef, updatedData);
            
          } catch (error) {
            console.error('Error al actualizar el documento:', error);
          }
        
        } else {
          //console.log('No existe')
        }

    })  
    await Promise.all(promises);
  } catch (error) {
    console.error('Error al agregar el dato:', error);
  }
};

export const AddOrders = async(ord, docRef) =>{

  try{
    await setDoc(docRef, ord)
  }catch(error){
    SlackErrorHandle(`${new Date()}: adminservice.peadoby.com.ar -> Error en agregar orden -> ${error} -> Orden: ${ord}`)
    
  } 
}

export const GetTalleres = async () => {
        try {
          const datos = []
          const querySnapshot = await getDocs(collection(db, 'Usuarios'))
          querySnapshot.forEach((doc) => {
            datos.push(doc.data())
          })
          return datos
         
        } catch (error) {
          SlackErrorHandle(`${new Date()}: adminservice.peadoby.com.ar -> Error en obtener los datos de talleres -> ${error}`)
          return []
        }
      }

export const GetFactura = async () => {
        try {
          const datos = []
          const querySnapshot = await getDocs(collection(db, 'Facturaciones'))
          querySnapshot.forEach((doc) => {
            datos.push(doc.data())
          })
          return datos
         
        } catch (error) {
          SlackErrorHandle(`${new Date()}: adminservice.peadoby.com.ar -> Error en obtener los datos de talleres -> ${error}`)
          return []
        }
      }    

export const GetTaller = async (uid) => {
        try {
          const datos = []
          const docRef = query(collection(db, 'Usuarios'), where("UUID", "==", uid))
          
          const querySnapshot = await getDocs(docRef)
          querySnapshot.forEach((doc) => {
            datos.push(doc.data())
          })
          return datos
        } catch (error) {
          
          return []
        }
      }

export const GetRetaillist = async () => {
        try {
          const datos = []
          const querySnapshot = await getDocs(collection(db, 'Retails'))
          querySnapshot.forEach((doc) => {
         
          const d = doc.data();
          const i = {'id': doc.id};  
          datos.push({ ...d,...i});
          
          })
          
          return datos
         

        } catch (error) {
          console.log(error)
          return []
        }
}

export const GetClientList = async (Cuit) =>{
  try {
    const datos = []
    const docRef = query(collection(db, 'Lista_Clientes'), where("CUIT", "==", Cuit))

    const querySnapshot = await getDocs(docRef)
    querySnapshot.forEach((doc) => {
      datos.push(doc.data())
    })
    return datos
  } catch (error) {
    console.log(error)
    return []
  }
}

export const addDevoluciones = async(data) =>{
  try{
    const docRef = collection(db, 'Devoluciones');
    const add = await addDoc(docRef, data)
  }catch(error){
    console.log(error)
  }
}

export const getDevoluciones = async() =>{
  try {
    const datos = []
    const docRef = query(collection(db, 'Devoluciones'), where("Estado", "==", "open"))

    const querySnapshot = await getDocs(docRef)
    querySnapshot.forEach((doc) => {
      const d = doc.data();
      const i = {'id': doc.id};  
      datos.push({ ...d,...i});
    })
    return datos
  } catch (error) {
    console.log(error)
    return []
  }
}

export const updateWorkedField = async (documentId, itemCode) => {
  
  try {
    // Referencia al documento en Firestore
    const docRef = doc(db, 'Devoluciones', documentId);

    // Obtener el documento
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // Obtener los datos actuales
      const data = docSnap.data();

      // Verificar si el campo documentLine existe
      if (Array.isArray(data.DocumentLines)) {
        // Modificar el array documentLine
        const updatedDocumentLine = data.DocumentLines.map(item => {
          if (item.ItemCode === itemCode) {
             // Si el campo worked existe, verificar que sea distinto y menor que Quantity
             if (item.worked !== undefined && item.worked >= item.Quantity) {
              console.log(`No se puede incrementar worked, ya alcanzó o superó Quantity (${item.Quantity}).`);
              return item; // No modificar el objeto
            }
            return {
              ...item,
              worked: (item.worked || 0) + 1,
            };
          }
          return item;
        });

        // Actualizar el documento en Firestore
        await updateDoc(docRef, { DocumentLines: updatedDocumentLine });
        console.log(`Campo worked actualizado para ItemCode: ${itemCode}`);
      } else {
        console.log("El campo documentLine no es un array o no existe.");
      }
    } else {
      console.log("El documento no existe.");
    }
  } catch (error) {
    console.error("Error al actualizar el campo worked:", error);
  }
};



export const GetTallerlist = async () => {

      try {
        const datos = []
       
        const querySnapshot = await getDocs(collection(db, 'OrdenesTaller'))
        
        querySnapshot.forEach((doc) => {
        const d = doc.data();
       
        const i = {'Id': doc.id };  
        datos.push({ ...d,...i});
       //console.log(i)
        })
        
        return datos
       
      } catch (error) {
        console.log(error)
        return []
      }
      
    }


    export const GetRemitoslist = async () => {
      try {
        const datos = []
        const querySnapshot = await getDocs(collection(db, 'Remitos'))
        querySnapshot.forEach((doc) => {
          const d = doc.data();
          const i = { 'id':doc.id };
          datos.push({ ...d, ...i });
        })
     // console.log(datos)
        return datos
      } catch (error) {
        console.log(error)
        return []
      }
    }

export const AddRepuestoState = async (datosfila, estado) => {
  
  try {
    const querySnapshot = await getDocs(
      query(collection(db, "Ordenes"), where("iddoc", "==", datosfila.iddoc))
    );
    const updates = []; // Array para almacenar las promesas de actualización
    querySnapshot.forEach((doc) => {
      const repuestos = doc.data().Repuestos;
      const repuestosActualizados = repuestos.map((repuesto) => {
        if (repuesto.id && repuesto.id.toString() === datosfila.id.toString()) {
          const nuevoEstado = { [estado]: new Date().toLocaleString(), usuario: "Aca va a estar el mail del usuario" };
          const estadoActualizado = repuesto.Estado && Array.isArray(repuesto.Estado) ? [...repuesto.Estado, nuevoEstado] : [nuevoEstado];
          return { ...repuesto, Estado: estadoActualizado, UltEstado: estado };
        } else {
          return repuesto;
        }
      });

      const totalRepuestos = repuestos.length;
      // const despachados = repuestos.filter(repuesto => repuesto.UltEstado === "Despachado" && repuesto.UltEstado === "Cancelado" && repuesto.UltEstado === "sinStock").length;
      
      // Filtra los repuestos que no están en "Cancelado" ni "sinStock"
      const repuestosNoCanceladosNiSinStock = repuestosActualizados.filter(repuesto => 
        !["Cancelado", "sinStock"].includes(repuesto.UltEstado)
      );

      // Verifica si todos los repuestos filtrados están en "Despachado"
      const todosDespachados = repuestosNoCanceladosNiSinStock.every(repuesto => 
        repuesto.UltEstado === "Despachado"
      );

      // Verifica si todos los repuestos tienen UltEstado igual a "Cancelado" o "sinStock"
      const todosCanceladoOsinStock = repuestosActualizados.every(repuesto =>
        ["Cancelado", "sinStock"].includes(repuesto.UltEstado)
      );

      const dataActualizada = { Repuestos: repuestosActualizados, SparePart: estado === "Autorizado" ? true : false };
      // Agrega la promesa de actualización al array de promesas
      updates.push(updateDoc(doc.ref, dataActualizada).then(() => {
       // console.log("Documento actualizado:", doc.id);  
      }));

      // Si todos los repuestos están despachados, actualizar el estado general de la orden
      // if ((despachados +1) === totalRepuestos) {
      //   updates.push(updateDoc(doc.ref, { Estado: "Repuesto despachado" }));
      // }
      
      // Decide el estado general de la orden basado en las condiciones
      if (todosDespachados && repuestosNoCanceladosNiSinStock.length > 0) {
        updates.push(updateDoc(doc.ref, { Estado: "Repuesto despachado" }));
      } else if (todosCanceladoOsinStock) {
        updates.push(updateDoc(doc.ref, { Estado: "Repuestos procesados" }));
      }
    });
    // Espera a que todas las promesas de actualización se completen
    await Promise.all(updates);

   // console.log("Todos los documentos actualizados correctamente.");
    
  } catch (error) {
    SlackErrorHandle(`${new Date()}: adminservice.peadoby.com.ar -> Error en agregar estado del repuesto -> ${error} -> datos de la fila: ${datosfila} -> estado a actualizar: ${estado}`);
    console.error("Error en AddRepuestoState:", error);
  }
};

export const AddOrderState = async (iddoc, estado) => {
  
  try {
    const q = query(collection(db, "Ordenes"), where("iddoc", "==", iddoc))
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach(async (doc) => {
     const docRef = doc.ref;
     await updateDoc(docRef, {Estado: estado, EnviadoAGoldmund: false});
     console.log('Documento actualizado exitosamente');
    });
 } catch (error) {
   console.error('Error al actualizar el documento:', error);    
 }
};

export const AddDevState = async (iddoc) => {

  try {
    const q = query(collection(db, "Devoluciones"), where("DocNumSAP", "==", parseInt(iddoc, 10)))
    const querySnapshot = await getDocs(q);
  
    querySnapshot.forEach(async (doc) => {
      console.log(doc)
      const docRef = doc.ref;
      await updateDoc(docRef, {Estado: "Cerrado"});
      console.log('Documento actualizado exitosamente');
    });
 } catch (error) {
   console.error('Error al actualizar el documento:', error);    
 }
};

export const EditOrdenTallerState = async (datosfila, estado) => {
  
  try {
    const querySnapshot = await getDoc(doc(db, "OrdenesTaller", datosfila.Id))
    
    const producto = querySnapshot.data();
    if (producto.iddoc === datosfila.iddoc) { // verifica si el repuesto es igual al de la fila seleccionada
        const nuevoEstado = { [estado]: new Date().toLocaleString(), usuario:"Aca va a estar el mail del usuario"}; // Setea el nuevo estado a agregar
        
          if (producto.Acciones && Array.isArray(producto.Acciones)) {
             // verifica que exista y que sea array
            const estadoActualizado = [...producto.Acciones, nuevoEstado];
            
            return { ...producto, Acciones: estadoActualizado, UltEstado: estado};
          } else {
            return { ...producto, Acciones: [nuevoEstado], UltEstado: estado}; // si no existe historia crea uno nuevo
            
          }
        } else {
            return producto;
        }

      const dataActualizada = producto;
      
      // updateDoc(doc.ref, dataActualizada);
  } catch (error) {
    SlackErrorHandle(`${new Date()}: adminservice.peadoby.com.ar -> Error en agregar estado del repuesto -> ${error} -> datos de la fila: ${datosfila} -> estado a actualizar: ${estado}`)
    console.error("Error en AddRepuestoState:", error);
  }
};

export const GetSkuList = async() =>{
  try {
  // const docRef = doc(db, 'ListaPreciosTest', 'Skus')
  const docRef = doc(db, 'ListaPrecio', 'Skus') // Esta okay
  const docSnapshot = await getDoc(docRef);
  return docSnapshot.data()
  }catch(error){
    console.log(error)
  }
}

export const GetDolar = async() =>{
  try {
  const docRef = doc(db, 'ListaPrecios', 'Dolar')
  const docSnapshot = await getDoc(docRef);
  return docSnapshot.data()
  }catch(error){
    console.log(error)
  }
}


export const Getpvp = async() =>{
  try {
  const docRef = doc(db, 'Lista_Precios_PVP', 'WgphFVy1YD959NBajWwP')
  const docSnapshot = await getDoc(docRef);
  return docSnapshot.data()
  }catch(error){
    console.log(error)
  }
}

export const AddDolar = async(value) =>{
  try {
  const docRef = doc(db, 'ListaPrecios', 'Dolar')
  await updateDoc(docRef, {Cotizacion: value})
  }catch(error){
    console.log(error)
  }
}

export const addMdoCost = async(value) =>{
  try{
    const docRef = doc(db, "CostoManoDeObra", "4pqzpcr4b2pyxsE1xFJA")
    await updateDoc(docRef, value)
    await updateDoc(docRef, {Fecha: new Date()})
  }catch(error){
    console.log(error)
  }
}

export const GetCostoManoDeObra = async () => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1; // Nota: getMonth devuelve 0 para enero, 1 para febrero, etc.

  // Obtén el primer día del mes actual
  const firstDayOfMonth = new Date(`${currentYear-1}-${currentMonth}-01`);
  // const firstDayOfMonth = new Date(`${currentYear}-01-01`);

  // Obtén el primer día del siguiente mes para limitar la consulta
  const firstDayOfNextMonth = new Date(`${currentYear }-${currentMonth+1}-01`);

  // Realiza una consulta para obtener documentos dentro del rango del mes actual
  const q = query(
      collection(db, 'CostoManoDeObra'),
      where('Fecha', '>=', firstDayOfMonth),
      where('Fecha', '<', firstDayOfNextMonth)
  );

  try {
      const querySnapshot = await getDocs(q);

      // Verificar si hay documentos encontrados
      if (querySnapshot.size > 0) {
          // Obtener los datos de todos los documentos
          const data = querySnapshot.docs.map((doc) => doc.data());
          
          return data;
      } else {
          console.log('No se encontraron documentos de costo de mano de obra para el mes actual.');
          return null; // Puedes manejar el caso en el que no hay documentos encontrados
      }
  } catch (error) {
      console.error('Error al obtener los documentos:', error);
      return null;
  }
};

// subir datos falta subir los datos a firebase 

export const modificarOrdenTaller = async (documentId, formData) => {
  try {
    const docRef = doc(db, 'OrdenesTaller', documentId);
    const check2 = formData [0].modificado;
    // Logging for debugging

    // Actualizar parcialmente el documento utilizando updateDoc
    setDoc(docRef, check2)
          .then(() => {
              console.log('Documento actualizado exitosamente');
          })
          .catch((error) => {
              console.error('Error al actualizar el documento:', error);
          });
      console.log('Document updated successfully');

  } catch (error) {
    console.error(`${new Date()}: Error in modificarOrdenTaller -> ${error}`);
    // Handle specific errors if needed
    if (error.code === 'permission-denied') {
      console.error('Permission denied. Make sure you have the necessary permissions.');
    } else {
      console.error('Unknown error occurred.');
    }
  }
};

export const modificarRetail = async(documentoId,formulario) =>{
  const check1= formulario[0];
  const check2= documentoId;
   // console.log(check1)
   // console.log(check2)
    try{
      const docRef = doc(db, 'Retails', documentoId)
      setDoc(docRef , check1 )
              .then(() => {
                console.log('Documento actualizado exitosamente');
                  })
              .catch((error) => {
                console.error('Error al actualizar el documento:', error);
                            });
                console.log('Document updated successfully');

    } catch (error) {
        SlackErrorHandle(`${new Date()}: adminservice.peadoby.com.ar -> Error en agregar orden -> ${error} -> retail modf.`)
        // Handle specific errors if needed
        if (error.code === 'permission-denied') {
            console.error('Permission denied. Make sure you have the necessary permissions.');
        } else {
            console.error('Unknown error occurred.');
        }
    }
};

//---------------------------------------------------------------------------------------------------------
//----------------- updatestatefactura tine 3 partes :-----------------------------------------------------
//-----------------parte 2 maneja la subida de estado a Ordenes--------------------------------------------
//-----------------parte 3 maneja la subida de estado a Facturacion----------------------------------------
//---------------------------------------------------------------------------------------------------------

export const updatestatefactura = async (iddoc, state) => {
  //console.log(iddoc);

  try {
    const q = query(collection(db, "Facturaciones"), where("iddoc", "==", iddoc));
    const querySnapshot = await getDocs(q);
    for (const doc of querySnapshot.docs) {
      try {

        //console.log(doc.data())
        const ordenes = doc.data().Ordenes;
     //console.log(ordenes)
        const ordenesFiltradas = ordenes.map(orden => orden.iddoc); // Obtiene los iddoc de todas las ordenes
     //console.log(ordenesFiltradas)
        updateStateFacturaSecondPart(state,ordenesFiltradas)  
        updateStateFacturaTheertyPart(state,iddoc)    
        console.log("Documento actualizado correctamente");
      } catch (error) {
        console.error("Error al construir la referencia del documento:", error);
      }
    }
  } catch (error) {
    console.error("Error al actualizar el documento:", error);
  }
};

export const updateStateFacturaSecondPart = async (state, ordenes) => {
  try {
    for (const iddoc of ordenes) { //itera sobre todos los iddocs
      
    //  console.log(iddoc)
      const q = query(collection(db, 'Ordenes'), where('iddoc', '==', iddoc));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach(async (doc) => {
        try {
          // Actualizar el estado del documento
          const docRef = doc.ref;
          await updateDoc(docRef, { Estado: state });
          console.log('Documento actualizado exitosamente');
        } catch (error) {
          console.error('Error al actualizar el documento:', error);
        }
      });
    }
  } catch (error) {
    console.error('Error al actualizar el documento:', error);    
  }
}; 


export const updateStateFacturaTheertyPart = async (state, iddoc) => {
  //console.log(state,iddoc);
  try {
     const q = query(collection(db, "Facturaciones"), where("iddoc", "==", iddoc))
    const querySnapshot = await getDocs(q);

     querySnapshot.forEach(async (doc) => {
      const docRef = doc.ref;
      await updateDoc(docRef, { Estado: state });
      console.log('Documento actualizado exitosamente');
    });
  } catch (error) {
    console.error('Error al actualizar el documento:', error);    
  }
};
//---------------------------------------------------------------------------------------------------------
//---------------------------------------------------------------------------------------------------------
//---------------------------------------------------------------------------------------------------------


// SUBE LOS DATOS
export const NewRetail = async (retail) => {
  try {
  //  console.log("Subida de datos", retail);

    const retailCollectionRef = collection(db, 'Retails');
     await addDoc(retailCollectionRef, retail);
    //  toast.success('Datos cargados con éxito', {
    //         position: "top-left",
    //         autoClose: 2000,
    //         hideProgressBar: false,
    //         closeOnClick: true
    //     });
    //  setTimeout(()=>{ 
    //     window.location.reload();

    //   }, 2500)

    console.log('Documento actualizado exitosamente');
  } catch (error) {
    console.error('Error al actualizar el documento:', error);    
  }
};
 
export const NewRemito = async (remito) => {
  try {
  // Verificar si el cliente ya existe
  const queryRef = query(collection(db, 'Remitos'), where('Nombre', '==', remito.Nombre));
  const querySnapshot = await getDocs(queryRef);
 
  if (!querySnapshot.empty) {
    // El cliente existe, buscar remito en su lista
    const clienteDoc = querySnapshot.docs[0];
    const remitosArray = clienteDoc.data().Remitos;
  
    if (!remitosArray.some(item => item.Remito === remito.Remito)) {
      // El remito no existe en la lista, agregarlo
      
      await updateDoc(clienteDoc.ref, {
        Remitos: arrayUnion({
          Remito: remito.Remito,
          Bultos: remito.Bultos,
          Email: remito.Email,
          Fecha: new Date(),
          Trabajado: 0
        }),
      })
      toast.success('Datos cargados con exito',{
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true})
    }else {
      // El remito ya existe en la lista
      toast.error('El remito ya existe en la lista del cliente',{
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true
    })
    }
  } else {
    // El cliente no existe, crearlo y agregar el remito
    const docRef = doc(collection(db, 'Remitos'));
    await setDoc(docRef, {
      Nombre: remito.Nombre,
      Cuit: remito.Cuit,
      Remitos: [{Remito: remito.Remito,
                Bultos: remito.Bultos,
                Email: remito.Email,
                Fecha: new Date(),
                Trabajado:0,}]
    });
    toast.success('Datos cargados con exito',{
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true})
  }
  }catch(error){
    console.log(error)
  }
};

      
export const NewTaller = async(taller) =>{
      try{
        const docRef = doc(collection(db, 'Usuarios'))
        const datos = await setDoc(docRef, taller)
      }catch(error){
        console.log(error)
      }
}


export const GetCategorias  = async() =>{
  try{
      let cat = []
      // const querySnapshot = await getDocs(collection(db, "ListaPrecios"));
      const querySnapshot = await getDocs(collection(db, "ListaPrecio"));
      querySnapshot.forEach((doc) => {
        const d = doc.data();
        const i = {'Grupo': doc.id};
        cat.push({...d, ...i});
      });
      return cat
  }catch(error){
        console.log(error)
  }
}

export const GetRetails = async (cuit) => {
  try {
    const datos = []
    const docRef = query(doc(db, 'Retails'), where('cuit', '==', cuit))
    const querySnapshot = await getDocs(docRef)
    querySnapshot.forEach((doc) => {
      datos.push(doc.data())
    })
    return datos
  } catch (error) {
    console.log(error)
    return []
  }
}

export const Conect = async (CompanyDB, UserName, Password, url, ServiceLayer) => {
  
  try {
      var raw = JSON.stringify({
          "CompanyDB": CompanyDB,
          "UserName": UserName,
          "Password": Password
      });
      
      const response = await Promise.race([
          ServiceLayer(url).post('/Login', raw),
          new Promise((_, reject) =>
              setTimeout(() => reject(new Error('error al conectar con sap')), 7000)
          ),
      ])
      
      if (response.status === 200) {
          
          // dispatch(setSessionID(response.data.SessionId))
          console.log(`Conectado con Service Layer ${response.status}`)
          return response.status

      } else {
          console.log(`sin conexion ${response.status}`)
          SlackErrorHandle(`${new Date()}: -> Fallo en la conexion a sap `)
          ///return response.data.error.message.value
          return response.status
      }
  } catch (error) {
      console.log('error en la conexion con sap', error)

  }
};

export const GetRepuestos = (Categoria, Articulo) => {
  return new Promise((resolve, reject) => {
    // const docRef = doc(db, 'ListaPreciosTest', Categoria, 'Repuestos', Articulo);
    const docRef = doc(db, 'ListaPrecio', Categoria, 'Repuestos', Articulo);
    const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        resolve([docSnapshot.data()]);
      } else {
        resolve([]);
      }
    }, (error) => {
      reject(error);
    });

    return () => {
      unsubscribe();
    };
  });
};

export const AddRepuesto = async (Categoria, Articulo, data) => {
  // const docRef = collection(db, 'ListaPreciosTest', Categoria, 'Repuestos', Articulo)
  const docRef = collection(db, 'ListaPrecio', Categoria, 'Repuestos', Articulo)
  await setDoc(docRef, data)

}

export const AddRepuestoPrice = async (Categoria, Articulo, data) => {
  // const docRef = doc(db, 'ListaPreciosTest', Categoria, 'Repuestos', Articulo);
  const docRef = doc(db, 'ListaPrecio', Categoria, 'Repuestos', Articulo);
  // Obtener el documento existente
  const docSnapshot = await getDoc(docRef);
  if (docSnapshot.exists()) {
    const existingData = docSnapshot.data();
    
    // Actualizar el precio en todos los objetos de la lista
    const updatedData = existingData.repuestos.map((obj) => {
  
      if (obj[Object.keys(obj)]) {
        obj[Object.keys(obj)].PrecioTaller = data.margen/100 //obj[Object.keys(obj)].PrecioTaller + (obj[Object.keys(obj)].PrecioTaller * parseFloat(data.margen)/100); // Actualizar el margen en el objeto existente
        obj[Object.keys(obj)].FechaAct = new Date()
        obj[Object.keys(obj)].aplicado = data.margen + '%'
      } else {
        obj.PrecioTaller = data.PrecioTaller; // Agregar el objeto PrecioTaller si no existe
      }
      return obj;
    });

    // Guardar el documento actualizado
    //console.log({repuestos:updatedData})
    await setDoc(docRef, {repuestos:updatedData});
  }
};

export const updatePrecioTaller = async (Categoria, Articulo, Codigo, updatedPriceTaller) => {
  try{
  // const docRef = doc(db, 'ListaPreciosTest', Categoria, 'Repuestos', Articulo);
  const docRef = doc(db, 'ListaPrecio', Categoria, 'Repuestos', Articulo);
  const docSnapshot = await getDoc(docRef);
  if (docSnapshot.exists()) {
    const existingData = docSnapshot.data();
    
    // Actualizar el precio en todos los objetos de la lista
    const updatedData = existingData.repuestos.map((obj) => {
      // Verificar si es el objeto que deseas actualizar
     
      if ([Object.keys(obj)][0][0] === Codigo[0]) {
        
        obj[Codigo[0]].aplicado = (updatedPriceTaller * 100).toFixed(2).toString() + '%' //(((updatedPriceTaller - obj[Codigo[0]].PrecioTaller) / obj[Codigo[0]].PrecioTaller) * 100).toFixed(2).toString() + '%'
        obj[Codigo[0]].PrecioTaller = updatedPriceTaller;
        obj[Codigo[0]].FechaAct = new Date();
        // Crear un nuevo objeto con el campo PrecioTaller actualizado
        // return {
        //   ...obj[Codigo[0]],
        //   PrecioTaller: updatedPriceTaller,
        //   FechaAct: new Date()
        // };
      }
      // Mantener los demás objetos sin cambios
      return obj;
    });

    // Guardar el documento actualizado
    await setDoc(docRef, {repuestos:updatedData});
  }
  }catch (error) {
  console.log(error)
  return []
  }
};

export const SLCred = () => {

  const [cred, setCred] = useState()
  const { user } = useAuth();

  useEffect(() => {
      if (user) {
          const Get = async () => {
              const docRef = doc(db, 'ServiceLayerCred', 'Credenciales')
              const docSnap = () => getDoc(docRef)
              const querySnapshot = await docSnap();
              const userd = querySnapshot.data();
              setCred(userd)
          }
          Get();
      }
  }, [user]);
  return cred
}

// export const GetPerfil = (id) => {
//   return new Promise(async(resolve, reject) => {
//     try {
//     const docRef = query(collection(db, 'Usuarios'), where("UUID", "==", id)); 
//     await onSnapshot(docRef, (query) => {
//       let dato = [];
//       query.forEach(doc => {
//         const d = doc.data();
//         const i = {'Id': doc.id};
//         dato.push({...d, ...i});
//       });
//       resolve(dato);
//     }, reject);
//   });
// };

export const GetPerfil = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = query(collection(db, 'Usuarios'), where("UUID", "==", id));
      const unsubscribe = onSnapshot(docRef, (querySnapshot) => {
        let dato = [];
        querySnapshot.forEach((doc) => {
          const d = doc.data();
          const i = { 'Id': doc.id };
          dato.push({ ...d, ...i });
        });
        resolve(dato);
      }, (error) => {
        reject(error);
      });

      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.error("Error en GetPerfil:", error);
      reject(error);
    }
  });
};

export const GetImage = async(name) => {
  const extensions = ['.jpg', '.jpeg', '.png', '.pdf', '.jfif']
  try {
    const storage = await getStorage();

    for (const extension of extensions) {
        const storageRef = ref(storage, `${name}${extension}`);
        try {
            const url = await getDownloadURL(storageRef);
            return url;
        } catch (error) {
            // La imagen no existe con esta extensión, continuar con la siguiente
            console.log(`Intentando con la extensión ${extension}`);
        }
    }

    // Si ninguna extensión retorna una URL, la imagen no existe
    console.error(`No se encontró la imagen con el nombre ${name}`);
    return null;
  } catch (error) {
    console.error(`Error al obtener la imagen:`, error);
    return null;
  }
}

export const addPedidoRepuesto = async(docref, order) => {
  try{
    
    await setDoc(docref, order)
    
  }catch(error){
    console.log(error)
  } 
}

export const Actualizar = async (estado, resInput, usuario, _ , lote) => {
  //Modificar la busqueda por repuesto, implementar un unique id
  const id = resInput.split("%")[0]
  try {
    const querySnapshot = await getDocs(
      query(collection(db, "Ordenes"), where("iddoc", "==", id))
    );
    
    querySnapshot.forEach(async (docy) => {
        
      const repuestos = docy.data().Repuestos;
      const repuestosActualizados = repuestos.map((repuesto) => {
        if (repuesto.id.toString() === resInput.split("%")[1]) { //Agregar al pedido de repuestos el unique id
            
            const nuevoEstado = { [estado]: new Date().toLocaleString(), usuario:usuario.email};
          if (repuesto.UltEstado !== "Cancelado"){
            if (repuesto.Estado && Array.isArray(repuesto.Estado)) {
              const estadoActualizado = [...repuesto.Estado, nuevoEstado];
              
              if(estado === 'despacho'){
                
                return { ...repuesto, Estado: estadoActualizado, UltEstado: estado, Lote:lote};
              }else{
                return { ...repuesto, Estado: estadoActualizado, UltEstado: estado };
              }
              
            } else {
              return { ...repuesto, Estado: [nuevoEstado], UltEstado: estado };
            }
          } else {
            window.alert(`Pedido Cancelado ${repuesto.Articulo} ${repuesto.Descripcion}, id:${id}`)
            return repuesto;
          }
        }
      });

      const index = repuestos.findIndex(repuesto => repuesto.id.toString() === resInput.split("%")[1]);
      
      if (index !== -1) {
        repuestos[index] = repuestosActualizados[index];

        const dataActualizada = {
          Repuestos: repuestos,
        };
        
        const docRef = doc(db, "Ordenes", docy.id);
        await updateDoc(docRef, dataActualizada);
        
      } else {
        SlackErrorHandle(`${new Date()}: adminservice.peadoby.com.ar -> Error No se encontró el índice correspondiente -> datos de la fila: ${resInput} -> estado a actualizar: ${estado}`)
        window.alert("No se encontró el índice correspondiente");
      }
      
      // const dataActualizada = {
      //   Repuestos: repuestosActualizados,
      // };

      // const docRef = doc(db, "Ordenes", docy.id);
      // // console.log(dataActualizada) // Debemos lograr que el array entregado posea las modificaciones requeridas
      // await updateDoc(docRef, dataActualizada);
      
    });
  } catch (error) {
    SlackErrorHandle(`${new Date()}: adminservice.peadoby.com.ar -> Error en agregar estado del repuesto -> ${error} -> datos de la fila: ${resInput} -> estado a actualizar: ${estado}`)
    window.alert(`Error en Actualizar: ${resInput} -> ${error}`);
  }
};


